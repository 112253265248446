import React, { useEffect, useState } from "react";
import { Button, Input } from "reactstrap";
import Loader from "../Loader";
import Svg from "../Svg";
import { dateTimeFormat, timeFormat } from "../../helpers/common";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import ChatClient from "../../helpers/chat";
import authProvider from "../../helpers/authProvider";
import moment from "moment";
import throttle from "lodash.throttle";
import debounce from "lodash.debounce";
import { ChatEvents } from "../../helpers/chat-types";

const ChatMessage = ({ message, ...props }) => {
  const currentUser = authProvider.getUser();
  const messageDate = moment(message.CreatedAt);

  return (
    <div
      className={`chat-message ${currentUser.id === message.UserId ? "self" : "other-party"}`}
      {...props}
    >
      <div className="chat-message-text">{message.MessageBody}</div>
      <div className="chat-message-time">
        {messageDate.isSame(new Date(), "date")
          ? messageDate.format(timeFormat)
          : messageDate.format(dateTimeFormat)}
      </div>
    </div>
  );
};

const ChatComponent = ({ chatRoomId, ...props }) => {
  const { t } = useTranslation("common");
  const currentUser = authProvider.getUser();
  const pageLimit = 15;
  const timelineRef = React.useRef();
  const [messageText, setMessageText] = useState("");
  const [scrollDisabled, setScrollDisabled] = useState(false);
  const [chatDisabled, setChatDisabled] = useState(true);
  const [isChatOpen, _setChatOpen] = useState(false);
  const [nextPageToken, setNextPageToken] = useState();
  const [chatClient, _setChatClient] = useState(null);
  const [otherParty, _setOtherParty] = useState(null);
  const [isTyping, setIsTyping] = useState(false);
  const [messages, setMessages] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  const chatOpenRef = React.useRef(isChatOpen);
  const setChatOpen = (isOpen) => {
    chatOpenRef.current = isOpen;
    _setChatOpen(isOpen);
  };

  const chatClientRef = React.useRef(chatClient);
  const setChatClient = (newClient) => {
    chatClientRef.current = newClient;
    _setChatClient(newClient);
  };

  const otherPartyRef = React.useRef(otherParty);
  const setOtherParty = (newParty) => {
    otherPartyRef.current = newParty;
    _setOtherParty(newParty);
  };

  useEffect(() => {
    if (chatClientRef.current !== null) {
      chatClientRef.current.disconnect();
    }

    let chat = new ChatClient(process.env.REACT_APP_CHAT_URL, () => {
      const session = authProvider.getSession();
      if (session && session.accessToken) {
        return session.accessToken;
      }
      return undefined;
    });

    chat.on(ChatEvents.onConnected, onChatConnected);
    chat.on(ChatEvents.onDisconnected, onDisconnected);
    chat.on(ChatEvents.onTypingStatusChanged, onTypingStatusChanged);
    chat.on(ChatEvents.onChatRoomReceived, onChatRoomDetailsReceived);
    chat.on(ChatEvents.onMessagesHistoryReceived, onMessagesHistoryReceived);
    chat.on(ChatEvents.onMessagesReceived, addNewMessage);
    chat.on(ChatEvents.onMarkAsRead, onAllRead);

    setChatClient(chat);

    return function cleanup() {
      if (chat) {
        try {
          chat.off(ChatEvents.onConnected, onChatConnected);
          chat.off(ChatEvents.onDisconnected, onDisconnected);
          chat.off(ChatEvents.onTypingStatusChanged, onTypingStatusChanged);
          chat.off(ChatEvents.onChatRoomReceived, onChatRoomDetailsReceived);
          chat.off(ChatEvents.onMessagesHistoryReceived, onMessagesHistoryReceived);
          chat.off(ChatEvents.onMessagesReceived, addNewMessage);
          chat.off(ChatEvents.onMarkAsRead, onAllRead);
          chat.disconnect();
        } catch (err) {
          console.error(err);
        }
      }
    };
  }, []);

  useEffect(() => {
    if (chatClient !== null) {
      chatClient.connect();
    }
  }, [chatClient]);

  useEffect(() => {
    if (isChatOpen) {
      markAllAsReadFunc();
    }
  }, [isChatOpen]);

  const onChatConnected = (e) => {
    if (chatClientRef.current) {
      chatClientRef.current.requestChatRoom(chatRoomId);
      setChatDisabled(false);
    }
  };

  const setTypingStatusFunc = React.useRef(
    throttle(() => {
      if (chatClientRef && chatClientRef.current) {
        chatClientRef.current.setTypingStatus(chatRoomId, true);
      }
    }, 2000)
  ).current;

  const resetTypingStatusFunc = React.useRef(
    debounce(() => {
      if (chatClientRef && chatClientRef.current) {
        chatClientRef.current.setTypingStatus(chatRoomId, false);
      }
    }, 4000)
  ).current;

  const markAllAsReadFunc = React.useRef(
    debounce(() => {
      if (chatClientRef && chatClientRef.current) {
        chatClientRef.current.markChatRoomAsRead(chatRoomId);
      }
    }, 2000)
  ).current;

  const onDisconnected = (e) => {
    setChatDisabled(true);
  };

  const onAllRead = (e) => {
    setUnreadCount(0);
  };

  const onChatRoomDetailsReceived = (e) => {
    const messageData = e ? e.detail : null;

    if (
      messageData &&
      messageData.Result &&
      messageData.Data &&
      messageData.Data.ChatRoom &&
      messageData.Data.ChatRoom.Id === chatRoomId
    ) {
      const secondParty = messageData.Data.ChatRoom.Participants.find(
        (p) => p.UserId !== currentUser.id
      );
      const myselfParty = messageData.Data.ChatRoom.Participants.find(
        (p) => p.UserId === currentUser.id
      );
      setOtherParty(secondParty);

      if (myselfParty) {
        setUnreadCount(myselfParty.NumberOfUnReadMessages);
      }

      if (chatClientRef.current) {
        chatClientRef.current.subscribeToChatRoom(chatRoomId);
        chatClientRef.current.getMessages(chatRoomId, nextPageToken, pageLimit);
      }
    }
  };

  const onMessagesHistoryReceived = (e) => {
    const historyData = e ? e.detail : null;

    try {
      if (historyData && historyData.Result) {
        const oldMessages = historyData.Data.Items;
        setMessages((prev) =>
          prev.concat(oldMessages.filter((om) => !prev.find((m) => m.Id === om.Id)))
        );
        setNextPageToken(historyData.Data.NextToken);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onFetchMoreMessages = () => {
    if (chatClientRef.current) {
      chatClientRef.current.getMessages(chatRoomId, nextPageToken, pageLimit);
    }
  };

  const onTypingStatusChanged = (e) => {
    const messageData = e ? e.detail : null;

    if (
      messageData &&
      messageData.Data &&
      messageData.Data.ChatRoomId === chatRoomId &&
      otherPartyRef &&
      otherPartyRef.current.UserId === messageData.Data.UserId
    ) {
      setIsTyping(messageData.Data.IsTyping);
    }
  };

  const addNewMessage = (e) => {
    const messageData = e ? e.detail : null;

    try {
      if (messageData && messageData.Result && messageData.Data) {
        setScrollDisabled(true);
        const msg = messageData.Data.hasOwnProperty("Message")
          ? messageData.Data.Message
          : messageData.Data;

        if (msg.ChatRoomId === chatRoomId) {
          setMessages((prev) => [msg, ...prev]);

          setTimeout(() => {
            timelineRef.current.scrollTo(0, timelineRef.current.scrollHeight);
            setScrollDisabled(false);
          }, 200);

          if (chatOpenRef && chatOpenRef.current === true) {
            markAllAsReadFunc();
          } else {
            setUnreadCount((prev) => prev + 1);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const sendMessage = (message) => {
    message = (message || "").trim();

    if (message.length > 497) {
      message = message.substring(0, 497) + "...";
    }

    if (chatClientRef.current && message && message.length > 0) {
      chatClientRef.current.sendMessage(chatRoomId, message);
      setMessageText("");
    }
  };

  return (
    <React.Fragment>
      <div
        {...props}
        className="chat-container"
        style={{
          display: isChatOpen ? "flex" : "none",
        }}
      >
        <div className="chat-header">
          {otherParty && (
            <strong className="main-text text-normal">{`${otherParty.User.FullName}`}</strong>
          )}
          <div
            className="chat-collapse-button"
            onClick={() => {
              setChatOpen(false);
            }}
          >
            <Svg name="minus" />
          </div>
        </div>
        <hr className="m-0" />
        <div className="chat-messages-container">
          <div
            id="scrollableDiv"
            style={{
              height: 280,
              overflow: "auto",
              display: "flex",
              flexDirection: "column-reverse",
            }}
            ref={timelineRef}
          >
            {otherParty && isTyping && (
              <span className="chat-typing-status">{`${otherParty.User.FullName} ${t(
                "chat.personTyping"
              )}`}</span>
            )}
            <InfiniteScroll
              dataLength={messages.length}
              next={onFetchMoreMessages}
              style={{ display: "flex", flexDirection: "column-reverse" }}
              inverse={true}
              hasMore={nextPageToken !== null && !scrollDisabled}
              loader={<Loader />}
              scrollableTarget="scrollableDiv"
            >
              {messages.map((item) => (
                <ChatMessage key={item.Id} message={item} />
              ))}
            </InfiniteScroll>
          </div>

          {chatDisabled && (
            <div className="chat-loader">
              <Loader title={t("chat.connecting")} />
            </div>
          )}
        </div>
        <hr className="m-0" />
        <div className="chat-input">
          <Input
            disabled={chatDisabled}
            value={messageText}
            onChange={(e) => setMessageText(e.target.value)}
            type="text"
            maxLength={500}
            placeholder={t("chat.placeholder")}
            onKeyPress={(e) => {
              if (e.charCode === 13 && e.target.value && e.target.value.length > 0) {
                sendMessage(e.target.value);
              }
            }}
            onKeyUp={resetTypingStatusFunc}
            onKeyDown={setTypingStatusFunc}
          />
          <Svg
            name="send"
            className={chatDisabled || messageText.trim().length === 0 ? "disabled" : ""}
            title={t("chat.sendBtn")}
            onClick={() => {
              if (!chatDisabled && messageText.trim().length > 0) {
                sendMessage(messageText);
              }
            }}
          />
        </div>
      </div>

      <Button
        onClick={() => {
          setChatOpen(!chatOpenRef.current);
        }}
        color="primary"
        className="d-flex flex-column items-center justify-content-center chat-button"
        style={{ width: "46px", height: "46px", borderRadius: "50%", padding: "0" }}
      >
        {unreadCount > 0 && <div className="chat-unread-badge"></div>}
        <Svg name="chat" className="text-white" style={{ width: "20px", height: "20px" }} />
      </Button>
    </React.Fragment>
  );
};

export default ChatComponent;
