import React from "react";
import { useHistory } from "react-router-dom";
import { EDIT_APPOINTMENT_URL } from "../../urls";
import { Card, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import Svg from "../Svg";

const AppointmentPendingCard = (props) => {
  const { t } = useTranslation("common");
  const history = useHistory();

  const goToAppointment = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(EDIT_APPOINTMENT_URL.replace(":id", props.appointment.appointmentId));
  };

  return (
    <Card
      onClick={(e) => goToAppointment(e)}
      style={{ cursor: "pointer" }}
      className="appointment-card flex-grow-1"
    >
      <CardBody className="scheduled-appt d-flex flex-column flex-grow-1">
        <div className="d-flex flex-column gap-4 mb-2 flex-grow-1" style={{ paddingRight: "96px" }}>
          <span className="theme-dark-text bold text-normal mr-auto">
            #{props.appointment.appointmentId}
          </span>

          <span className="main-text text-normal mr-auto">
            {t("schedule.asap")}, {t("appointment.pending")}
          </span>

          <span className="theme-dark-text bold text-normal mr-auto mt-3">
            {t("appointmentView.technicianAssigned")}
          </span>

          <span className="main-text text-normal mr-auto">
            {props.appointment.technician
              ? `${props.appointment.technician.firstName} ${props.appointment.technician.lastName}`
              : "--"}
          </span>

          {props.appointment.corporateProfile && (
            <div className="d-flex flex-column">
              <span className="theme-dark-text bold text-normal mr-auto mt-3">
                {t("corporate.corporateAccount")}
              </span>

              <span className="main-text text-normal mr-auto">
                {props.appointment.corporateProfile.name}
              </span>
            </div>
          )}
        </div>

        {props.appointment.summary[0].picture?.url ? (
          <img
            src={props.appointment.summary[0].picture.url}
            className="service-image"
            alt="Appointment Service"
          />
        ) : (
          <div
            className="service-image d-flex items-center justify-content-center"
            style={{ opacity: 0.7 }}
          >
            <Svg name="avvy-shape" className="sub-text" style={{ width: "32px", height: "32px" }} />
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default AppointmentPendingCard;
