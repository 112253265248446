import React, { useState, useEffect, forwardRef } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeCard from "./card";
import { useTranslation } from "react-i18next";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_URL);

const StripeCardElement = forwardRef((props, ref) => {
  const { i18n } = useTranslation("common");
  const [loaded, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);
  }, []);

  return (
    <React.Fragment>
      {loaded && props.isVisible && (
        <Elements options={{ locale: i18n.language }} stripe={stripePromise}>
          <StripeCard ref={ref} onCardAdded={props.onCardAdded} toggle={props.toggle} />
        </Elements>
      )}
    </React.Fragment>
  );
});

export default StripeCardElement;
