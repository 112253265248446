import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import axios from "axios";
import SideBar from ".";
import { useTranslation } from "react-i18next";
import Svg from "../Svg";
import { formatFullAddress, parseError } from "../../helpers/common";
import CheckoutDetails from "../CheckoutDetails";

const RescheduleCheckoutSideBar = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [costData, setCostData] = useState(null);
  const [appointment, setAppointment] = useState(null);
  const [scheduledDate, setScheduledDate] = useState(null);
  const [people, setPeople] = useState([]);
  const [address, setAddress] = useState(null);
  const { t, i18n } = useTranslation("common");

  useEffect(() => {
    if (props.isOpen && props.rescheduleOptions) {
      initializeCheckout(props.rescheduleOptions);
    } else {
      setError(null);
      setLoading(false);
      setCostData(null);
      setPeople([]);
      setAddress(null);
      setScheduledDate(null);
      setAppointment(null);
    }
  }, [props.rescheduleOptions, props.isOpen]);

  const initializeCheckout = (options) => {
    const appointment = options.appointment;
    const newDate = options.date;

    const peopleList = appointment.appointmentPatients;
    peopleList.forEach((person) => {
      const services = person.appointmentItems.map((ai) => {
        const service = Object.assign({ key: ai.key }, ai.service);

        if (service.requisitionFormId) {
          service.requisitionFormFile = service.requisitionFormId;
        }

        if (service.insuranceFormId) {
          service.insuranceFormFile = service.insuranceFormId;
        }

        return service;
      });
      person.services = services;
    });

    setPeople(peopleList);
    setAddress(
      Object.assign(
        Object.assign(
          {
            fullAddress: formatFullAddress(appointment.address),
            provinceCode: appointment.address.province,
          },
          appointment.address
        ),
        appointment.location
      )
    );
    setScheduledDate(newDate);
    setAppointment(appointment);
  };

  const onConfirmReschedule = () => {
    setLoading(true);
    setError(null);

    axios
      .patch(`${process.env.REACT_APP_API_URL}/appointments/${appointment.id}`, {
        year: scheduledDate.getFullYear(),
        month: scheduledDate.getMonth() + 1,
        day: scheduledDate.getDate(),
        hour: scheduledDate.getHours(),
        minute: scheduledDate.getMinutes(),
      })
      .then((res) => {
        setLoading(false);
        props.onRescheduled(res.data);
        props.toggle();
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        setError(error);
      });
  };

  const Footer = () => {
    return (
      <div className="d-flex items-center">
        <Button
          size="sm"
          className="px-5 py-2 ml-auto btn-accent"
          disabled={costData == null || loading}
          onClick={() => onConfirmReschedule()}
        >
          {t("cancelAppointment.btn")}
        </Button>
      </div>
    );
  };

  return (
    <SideBar
      loading={loading}
      error={error}
      onOverlayDismiss={() => setError(null)}
      title={t("schedule.reschedule")}
      {...props}
      footer={<Footer />}
    >
      <div className="d-flex flex-column gap-12">
        {scheduledDate != null && (
          <div className="avvy-card d-flex flex-column gap-12">
            <div className="d-flex flex-row gap-12">
              <Svg name="calendar-day-blue" className="avvy-card-icon" />
              <span className="avvy-card-header">{t("newBooking.schedule")}</span>
            </div>
            <div className="d-flex flex-row gap-12">
              <div className="avvy-card-small d-flex flex-column">
                <span className="theme-dark-text bold">
                  {new Intl.DateTimeFormat(i18n.language, { month: "long" }).format(scheduledDate)}{" "}
                  {scheduledDate.getDate()}
                </span>
                <span className="sub-text" style={{ fontSize: "14px" }}>
                  {new Intl.DateTimeFormat(i18n.language, { weekday: "long" }).format(
                    scheduledDate
                  )}
                </span>
              </div>

              <div className="avvy-card-small d-flex flex-column">
                <span className="theme-dark-text bold">
                  {new Intl.DateTimeFormat(i18n.language, {
                    hour: "numeric",
                    minute: "numeric",
                  })
                    .format(scheduledDate)
                    .replace(/^0+/, "")}
                </span>
                <span className="sub-text" style={{ fontSize: "14px" }}>
                  {t("appointmentDetails.time")}
                </span>
              </div>
            </div>
          </div>
        )}

        {appointment != null && (
          <div className="d-flex flex-column gap-24">
            <CheckoutDetails
              showPaidAmount={true}
              appointment={appointment}
              discountCode={appointment.discountCodeUsed}
              address={address}
              scheduledDate={scheduledDate}
              appointmentType={appointment.type}
              people={people}
              costUpdated={(cost) => {
                setCostData(cost);
              }}
            />
          </div>
        )}
      </div>
    </SideBar>
  );
};

export default RescheduleCheckoutSideBar;
