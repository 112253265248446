import React, { useState, useRef, useEffect } from "react";
import SideBar from ".";
import { Button } from "reactstrap";
import Svg from "../../components/Svg";
import { formatMoney } from "../../helpers/common";
import { useTranslation } from "react-i18next";

const RequestFormSidebar = (props) => {
  const ref = useRef(null);
  const [imageFile, setImageFile] = useState(null);
  const [previewImage, setPreviewImage] = useState();
  const { t, i18n } = useTranslation("common");

  useEffect(() => {
    cleanup();

    if (props.isOpen && props.selectedService) {
      if (
        props.selectedService.requisitionFormFile &&
        typeof props.selectedService.requisitionFormFile !== "string"
      ) {
        try {
          const file = props.selectedService.requisitionFormFile;
          setImageFile(file);
          setPreviewImage(URL.createObjectURL(file));
        } catch (err) {
          console.error(err);
        }
      }
    }
  }, [props.isOpen]);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      try {
        const file = event.target.files[0];
        setImageFile(file);
        setPreviewImage(URL.createObjectURL(file));
      } catch (err) {
        console.error(err);
      }
    }
  };

  const cleanup = () => {
    if (previewImage) {
      try {
        URL.revokeObjectURL(previewImage);
      } catch (err) {
        console.error(err);
      }
    }

    setPreviewImage(null);
    setImageFile(null);

    if (ref && ref.current && ref.current.value) {
      try {
        ref.current.value = "";
      } catch (err) {}
    }
  };

  const Footer = () => {
    return (
      <div className="d-flex flex-row items-center">
        <div className="d-flex flex-column ml-2">
          <span className="theme-dark-text bold" style={{ fontSize: "18px" }}>
            {formatMoney(
              props.selectedService && props.selectedService.price > 0
                ? props.selectedService.price
                : 0,
              i18n.language
            )}
          </span>
          <span className="sub-text" style={{ fontWeight: "300", fontSize: "14px" }}>
            {t("newBooking.basePrice")}
          </span>
        </div>

        <Button
          size="sm"
          style={{ marginRight: "24px" }}
          className="px-5 py-2 ml-auto btn-accent"
          disabled={!imageFile}
          onClick={() => {
            let updatedService = Object.assign(
              { requisitionFormFile: imageFile },
              props.selectedService
            );
            props.addService(updatedService);
            props.toggle();
            cleanup();
          }}
        >
          {t("requisitionForm.btn")}
        </Button>
      </div>
    );
  };

  return (
    <React.Fragment>
      <SideBar
        onBack={() => {
          if (props.onBack) {
            props.onBack();
          }
        }}
        back={true}
        onClose={() => cleanup()}
        title={t("requisitionForm.title")}
        {...props}
        footer={<Footer />}
      >
        {previewImage ? (
          <div
            style={{
              overflow: "hidden",
              position: "relative",
              border: "1px solid var(--border-color)",
              borderRadius: "6px",
            }}
            className="upload d-flex flex-column"
          >
            <Button
              onClick={() => cleanup()}
              color="link"
              style={{
                margin: 0,
                padding: 0,
                color: "var(--error-color)",
                position: "absolute",
                right: "6px",
                top: "6px",
              }}
            >
              <Svg name="icon_cancel" style={{ width: "24px", height: "24px" }} />
            </Button>

            <img
              style={{
                maxWidth: "100%",
                objectPosition: "top",
                objectFit: "cover",
              }}
              alt="Form Preview"
              src={previewImage}
            />
          </div>
        ) : (
          <div className="upload-file">
            <div
              className="upload-file-box"
              style={{ cursor: "pointer" }}
              onClick={() => {
                ref.current.click();
              }}
            >
              <div className="d-flex flex-column items-center gap-6">
                <Svg
                  name="icon_camera"
                  className="theme-dark-text"
                  style={{ width: "32px", height: "32px" }}
                />
                <span className="theme-dark-text">{t("requisitionForm.text")}</span>
              </div>
            </div>
          </div>
        )}
      </SideBar>

      <input
        id="requisition-form-file-input"
        name="requisition-form-file-input"
        type="file"
        accept=".jpeg,.jpg,.png"
        ref={ref}
        onChange={handleImageChange}
        style={{ display: "none" }}
      />
    </React.Fragment>
  );
};

export default RequestFormSidebar;
