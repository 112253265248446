import React, { useState, useEffect } from "react";
import SideBar from ".";
import { FormGroup, Input, Label, Button, Dropdown, InputGroup, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { parseError } from "../../helpers/common";
import Svg from "../Svg";

const FeedBackSidebar = (props) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [notes, setNotes] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [provincesOpen, setProvincesOpen] = useState(false);

  const { t } = useTranslation("common");

  useEffect(() => {
    if (props.isOpen) {
      GetFeedbackCategories();
    } else if (!props.isOpen) {
      setNotes("");
      setSelectedCategory(null);
      setLoading(false);
      setCategories([]);
    }
  }, [props.isOpen]);

  const GetFeedbackCategories = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/feedbackcategories`)
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.error(err);
        setCategories([]);
      });
  };

  const reset = () => {
    setNotes("");
    setSelectedCategory(null);
    setLoading(false);
  };

  const submitFeedback = () => {
    setLoading(true);

    let data = {
      note: notes,
      feedbackCategoryId: selectedCategory,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/feedbacks`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        setLoading(false);
        reset();
        showSuccess(t("feedbackSubmitted.title"));
        props.toggle();
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        setError(error);
      });
  };

  const showSuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setNotes("");
    setSelectedCategory(null);
    setLoading(false);
  };

  return (
    <SideBar title={t("feedback.title")} {...props}>
      <div className="d-flex flex-column gap-16">
        <FormGroup>
          <Label className="bold-blue">{t("feedback.dropdown")}</Label>
          <InputGroup className="input-group-alternative">
            <Dropdown
              isOpen={provincesOpen}
              toggle={() => setProvincesOpen((prev) => !prev)}
              direction="down"
              style={{ width: "100%", height: "44px" }}
            >
              <Svg
                className={provincesOpen ? "flip-y w-24 h-24" : "w-24 h-24"}
                style={{ position: "absolute", right: 10, top: 10, pointerEvents: "none" }}
                name="icon_down"
              />
              <DropdownToggle
                tag="div"
                data-toggle="dropdown"
                style={{ cursor: "pointer", padding: "9.5px 12px", width: "100%" }}
                aria-expanded={provincesOpen}
              >
                {selectedCategory
                  ? categories.find((c) => c.id === selectedCategory)
                    ? categories.find((c) => c.id === selectedCategory).name
                    : t("feedback.dropdown")
                  : t("feedback.dropdown")}
              </DropdownToggle>
              <DropdownMenu style={{ width: "100%", marginTop: 10, padding: "10px" }}>
                {categories &&
                  categories.map((category) => {
                    return (
                      <DropdownItem
                        key={category.id}
                        onClick={() => {
                          setSelectedCategory(category.id);
                        }}
                      >
                        {category.name}
                      </DropdownItem>
                    );
                  })}
              </DropdownMenu>
            </Dropdown>
          </InputGroup>
        </FormGroup>

        <FormGroup>
          <Input
            value={notes}
            style={{ height: 180 }}
            type="textarea"
            name="text"
            id="exampleText"
            placeholder={t("feedback.placeholder")}
            onChange={(e) => setNotes(e.target.value)}
          />
        </FormGroup>

        <Button onClick={() => submitFeedback()} size="md" className="btn-accent" disabled={!selectedCategory || !notes || loading}>
          {t("feedback.btnSubmit")}
        </Button>

        {error && (
          <div className="error-alert mt-3 mx-auto" style={{ padding: "0.6rem", maxWidth: "100%" }}>
            {error}
          </div>
        )}
      </div>
    </SideBar>
  );
};

export default FeedBackSidebar;
