import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { EDIT_APPOINTMENT_URL, VIEW_APPOINTMENT_URL } from "../../urls";
import { Button, Card, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Svg from "../Svg";

const AppointmentCard = (props) => {
  const { t, i18n } = useTranslation("common");
  const history = useHistory();
  const [startDate, setStartDate] = useState(null);
  const [canCancel, setCanCancel] = useState(false);
  const [canReschedule, setCanReschedule] = useState(false);

  const goToAppointment = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (
      props.appointment.status === "technician_in_transit" ||
      props.appointment.status === "technician_at_location" ||
      props.appointment.status === "started"
    ) {
      history.push(VIEW_APPOINTMENT_URL.replace(":id", props.appointment.appointmentId));
    } else {
      history.push(EDIT_APPOINTMENT_URL.replace(":id", props.appointment.appointmentId));
    }
  };

  useEffect(() => {
    const appStart = props.appointment.start;
    const start = moment(
      `${appStart.day}/${appStart.month}/${appStart.year} ${appStart.hour}:${appStart.minute}:${appStart.second}`,
      "DD/MM/YYYY HH:mm:ss"
    );

    setStartDate(start);

    const todaysApp = start.isSame(moment(), "day");

    setCanCancel(!todaysApp && props.appointment.status === "technician_assigned");
    setCanReschedule(props.appointment.status === "technician_assigned");

    let newDate = new Date(
      appStart.year,
      appStart.month,
      appStart.day,
      appStart.hour,
      appStart.minute
    );
    newDate.setMonth(newDate.getMonth() - 1);

    let isPastDay = moment(new Date(), "days").isAfter(newDate);

    if (
      isPastDay ||
      props.appointment.status === "technician_in_transit" ||
      props.appointment.status === "started" ||
      props.appointment.status === "technician_at_location"
    ) {
      setCanCancel(false);
      setCanReschedule(false);
    }
  }, [props.appointment]);

  const onCancel = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (canCancel && props.onCancel) {
      props.onCancel(props.appointment);
    }
  };

  const onReschedule = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (canReschedule && props.onReschedule) {
      props.onReschedule(props.appointment);
    }
  };

  return (
    <Card
      onClick={(e) => goToAppointment(e)}
      style={{ cursor: "pointer" }}
      className="appointment-card flex-grow-1"
    >
      <CardBody className="scheduled-appt d-flex flex-column flex-grow-1">
        <div className="d-flex flex-column gap-4 mb-2 flex-grow-1">
          <div className="d-flex flex-column gap-4" style={{ paddingRight: "96px" }}>
            <span className="theme-dark-text bold text-normal mr-auto">
              #{props.appointment.appointmentId}
            </span>
            {startDate && (
              <span className="main-text text-normal mr-auto">
                {new Intl.DateTimeFormat(i18n.language, {
                  dateStyle: "full",
                  timeStyle: "short",
                })
                  .format(startDate)
                  .replace(/^0+/, "")}
              </span>
            )}
          </div>

          <div className="d-flex flex-column">
            <span className="theme-dark-text bold text-normal mr-auto mt-3">
              {t("appointmentView.technicianAssigned")}
            </span>

            <span className="main-text text-normal mr-auto">
              {props.appointment.technician
                ? `${props.appointment.technician.firstName} ${props.appointment.technician.lastName}`
                : "--"}
            </span>
          </div>

          {props.appointment.corporateProfile && (
            <div className="d-flex flex-column">
              <span className="theme-dark-text bold text-normal mr-auto mt-3">
                {t("corporate.corporateAccount")}
              </span>

              <span className="main-text text-normal mr-auto">
                {props.appointment.corporateProfile.name}
              </span>
            </div>
          )}
        </div>

        {(canCancel || canReschedule) && (
          <div className="d-flex gap-6 ml-auto" style={{ marginBottom: "-2px" }}>
            {canCancel && (
              <Button onClick={onCancel} className="ml-auto" outline type="button">
                {t("dashboard.cancelBtn")}
              </Button>
            )}

            {canReschedule && (
              <Button onClick={onReschedule} color="secondary" type="button">
                {t("dashboard.reschedule")}
              </Button>
            )}
          </div>
        )}

        {props.appointment.summary[0].picture?.url ? (
          <img
            src={props.appointment.summary[0].picture.url}
            className="service-image"
            alt="Appointment Service"
          />
        ) : (
          <div
            className="service-image d-flex items-center justify-content-center"
            style={{ opacity: 0.7 }}
          >
            <Svg name="avvy-shape" className="sub-text" style={{ width: "32px", height: "32px" }} />
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default AppointmentCard;
