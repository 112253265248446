import React, { useEffect, useState, useRef, useCallback } from "react";
import Geocoder from "react-map-gl-geocoder";
import MapGL from "react-map-gl";
import {
  detectDefaultLanguage,
  formatFullAddress,
  formatStreetAddress,
} from "../../helpers/common";
import { useTranslation } from "react-i18next";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import "./styles.css";

const AddressInput = (props) => {
  const { t } = useTranslation("common");
  const geocoderContainerRef = useRef();
  const mapRef = useRef();
  const [addressString, setAddressString] = useState("");
  const [addressInput, setAddressInput] = useState("");
  const [viewport, setViewport] = useState({
    latitude: 45.552411,
    longitude: -73.688447,
    zoom: 11,
  });

  const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), []);
  const handleGeocoderViewportChange = useCallback((newViewport) => {
    const geocoderDefaultOverrides = { transitionDuration: 1000 };

    return handleViewportChange({
      ...newViewport,
      ...geocoderDefaultOverrides,
    });
  }, []);

  const onSelected = (result) => {
    let province, provinceCode, postCode, city;

    if (result.context) {
      result.context.forEach((v, i) => {
        if (v.id.indexOf("postcode") >= 0) {
          postCode = v.text;
        }
        if (v.id.indexOf("region") >= 0) {
          province = v.text;
          provinceCode = v.short_code.replace("CA-", "");
        }
        if (v.id.indexOf("place") >= 0) {
          city = v.text;
        }
      });
    }

    let street = `${result.address || ""} ${result.text}`.trim();
    let data = {
      latitude: result.center[1],
      longitude: result.center[0],
      province: province,
      provinceCode: provinceCode,
      city: city,
      postalCode: postCode,
      street: street,
      unitNumber: props.address && props.address.unitNumber ? props.address.unitNumber : "",
    };

    data.fullAddress = formatFullAddress(data);

    setAddressString(formatStreetAddress(data));

    if (props.onUpdated) {
      props.onUpdated(data);
    }
  };

  useEffect(() => {
    return function cleanup() {
      const inputElement = document.querySelector(
        ".inline-geocoder .mapboxgl-ctrl-geocoder--input"
      );
      const layout = document.getElementById("root");

      if (layout) {
        layout.removeEventListener("click", onOutsideClick);
      }

      if (inputElement) {
        inputElement.removeEventListener("focus", onInputFocused);
      }
    };
  }, []);

  useEffect(() => {
    if (props.address) {
      setAddressString(formatStreetAddress(props.address));
    } else {
      setAddressString("");
    }

    setAddressInput("");
  }, [props.address]);

  const onInputFocused = (e) => {
    setAddressInput(addressString);
  };

  const onOutsideClick = (e) => {
    const suggestions = document.querySelector(
      ".inline-geocoder .suggestions-wrapper .suggestions"
    );
    const inputElement = document.querySelector(".inline-geocoder .mapboxgl-ctrl-geocoder--input");

    if (inputElement && e.path && !e.path.includes(inputElement)) {
      inputElement.blur();
      if (suggestions) {
        suggestions.style.display = "none";
      }
    }
  };

  return (
    <div style={{ width: "100%", height: "44px" }} className="inline-geocoder">
      <div
        ref={geocoderContainerRef}
        style={{
          height: 44,
          display: "flex",
          alignItems: "center",
        }}
      />
      <MapGL
        ref={mapRef}
        {...viewport}
        style={{
          opacity: "0",
          userSelect: "none",
          cursor: "default",
          visibility: "hidden",
          height: 0,
        }}
        width="100%"
        height="100%"
        onViewportChange={handleViewportChange}
        mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
      >
        <Geocoder
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
          containerRef={geocoderContainerRef}
          mapRef={mapRef}
          style={{ width: "100%", height: "100%", padding: 0 }}
          onViewportChange={handleGeocoderViewportChange}
          placeholder={addressString || t("newBooking.addressPlaceholder")}
          onResult={(result) => onSelected(result.result)}
          marker={false}
          limit={4}
          minLength={3}
          inputValue={addressInput}
          collapsed={true}
          onInit={() => {
            const inputElement = document.querySelector(
              ".inline-geocoder .mapboxgl-ctrl-geocoder--input"
            );
            const layout = document.getElementById("root");

            if (layout) {
              layout.addEventListener("click", onOutsideClick);
            }

            if (inputElement) {
              inputElement.addEventListener("focus", onInputFocused);
            }
          }}
          onClear={() => {
            setAddressString("");
            setAddressInput("");
          }}
          countries="ca"
          types="address"
          language={detectDefaultLanguage().indexOf("fr") > -1 ? "fr" : "en"}
        />
      </MapGL>
    </div>
  );
};

export default AddressInput;
