import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, Card, CardBody, Input } from "reactstrap";
import axios from "axios";
import Loader from "../Loader";
import Svg from "../Svg";
import { parseError } from "../../helpers/common";
import { useTranslation } from "react-i18next";

const CancelModal = (props) => {
  const { t } = useTranslation("common");
  const [isOpen, setIsOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setReason("");
    setError(null);
    setLoading(false);
    setIsOpen(props.isOpen || false);
  }, [props.isOpen]);

  const onAppointmentCancel = () => {
    if (props.appointment) {
      setLoading(true);
      setError(null);
      axios
        .post(`${process.env.REACT_APP_API_URL}/appointments/${props.appointment.id || props.appointment.appointmentId}/cancel`, {
          reason: reason ? reason.trim() : null,
        })
        .then((res) => {
          setLoading(false);
          props.onCancelled(props.appointment.id);
          props.toggle();
        })
        .catch((err) => {
          let error = parseError(err);
          setError(error);
          setLoading(false);
        });
    }
  };

  return (
    <Modal className="modal-dialog-centered" style={{ maxWidth: 560, width: "95%" }} isOpen={isOpen} toggle={() => props.toggle()}>
      <ModalHeader
        close={
          <button className="close" onClick={() => props.toggle()}>
            <Svg name="icon_cancel_grey" className="w-24 h-24 sub-text" />
          </button>
        }
        style={{ borderBottom: 0, paddingBottom: 0 }}
      >
        <div className="text-muted text-center mt-0 mx-auto">
          <h5 className="mb-0">{t("cancelAppointment.title")}</h5>
        </div>
      </ModalHeader>
      <div className="modal-body p-0">
        <Card className="border-0">
          <CardBody style={{ padding: "0.5rem 1.25rem 1.25rem 1.25rem" }}>
            <div className="d-flex gap-16 flex-column">
              <div className="main-text text-normal">{t("cancelAppointment.text")}</div>

              <section className="discount-section d-flex flex-column gap-6">
                <span className="bold theme-dark-text">{t("cancelAppointment.reason")}</span>
                <Input
                  onChange={(e) => {
                    setReason(e.target.value);
                  }}
                  type="textarea"
                  rows={4}
                  maxLength={500}
                  disabled={loading}
                  value={reason}
                  className="main-text"
                  placeholder={t("cancelAppointment.reasonPlaceholder")}
                />
              </section>

              <hr className="m-0" />

              {(loading || error) && (
                <React.Fragment>
                  {loading && <Loader title={t("common.loading")} />}
                  {error && (
                    <div className="error-alert mt-3 mx-auto" style={{ padding: "0.6rem", maxWidth: "100%" }}>
                      {error}
                    </div>
                  )}

                  <hr className="m-0" />
                </React.Fragment>
              )}

              <div className="d-flex items-center gap-6 ml-auto flex-wrap">
                <Button disabled={loading} color="secondary" outline type="button" onClick={() => props.toggle()}>
                  {t("cancelAppointment.goBackBtn")}
                </Button>
                <Button disabled={loading} className="btn-accent" type="button" onClick={() => onAppointmentCancel()}>
                  {t("cancelAppointment.button")}
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

export default CancelModal;
