import React, { useEffect, useState } from "react";
import axios from "axios";
import SideBar from ".";
import { Button } from "reactstrap";
import AppDetails from "./AppDetails";
import Rating from "react-rating";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";
import Svg from "../../components/Svg/index.js";
import { formatMoney, parseError } from "../../helpers/common";
import moment from "moment";
import authProvider from "../../helpers/authProvider";

const HistorySidebar = (props) => {
  const { t, i18n } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [showApptDetailsSidebar, setShowApptDetailsSidebar] = useState(false);
  const [nextPageToken, setNextPageToken] = useState(null);

  useEffect(() => {
    if (props.isOpen) {
      getHistory();
    } else {
      setAppointments([]);
      setSelectedAppointment(null);
      setNextPageToken(null);
    }

    window.addEventListener("profileTypeChanged", onProfileTypeChanged, true);

    return function cleanup() {
      window.removeEventListener("profileTypeChanged", onProfileTypeChanged, true);
    };
  }, [props.isOpen]);

  const onProfileTypeChanged = () => {
    if (props.isOpen) {
      getHistory();
    }
  };

  const getHistory = (pageToken = null) => {
    setLoading(true);
    setError(null);

    if (!pageToken) {
      setAppointments([]);
    }

    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/Appointments/history?pageSize=15&isBackward=false&paginationToken=${
          pageToken || ""
        }&profile=${authProvider.getCurrentProfileType()}`
      )
      .then((res) => {
        setLoading(false);
        setAppointments((prev) =>
          prev.concat(
            res.data.items.filter((a) => !prev.find((aa) => aa.appointmentId === a.appointmentId))
          )
        );
        setNextPageToken(res.data.nextPageToken);
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        setError(error);
      });
  };

  const showAppDetails = (appointment) => {
    setSelectedAppointment(appointment);
    setShowApptDetailsSidebar(true);
  };

  const formateDate = (appStart, withTime = false) => {
    if (appStart) {
      const appMoment = moment(
        `${appStart.day}/${appStart.month}/${appStart.year} ${appStart.hour}/${appStart.minute}/${appStart.second}`,
        "DD/MM/YYYY HH:mm:ss"
      );
      const appDate = appMoment.toDate();

      if (withTime) {
        return `${new Intl.DateTimeFormat(i18n.language, {
          dateStyle: "medium",
          timeStyle: "short",
        }).format(appDate)}`;
      }

      return `${new Intl.DateTimeFormat(i18n.language, {
        dateStyle: "long",
      }).format(appDate)}`;
    } else {
      return "";
    }
  };

  return (
    <React.Fragment>
      <SideBar
        title={t("appointmentHistory.title")}
        back={true}
        {...props}
        style={{ backgroundColor: "#F1F2F2" }}
      >
        <div className="d-flex flex-column gap-24">
          <div className="main-text text-normal">{t("appointmentHistory.text")}</div>

          <div className="d-flex flex-column gap-24">
            {appointments.length > 0 &&
              appointments.map((appointment) => {
                return (
                  <div
                    key={appointment.appointmentId}
                    onClick={() => showAppDetails(appointment)}
                    className="appointment-card card px-4 py-3 cursor-pointer"
                  >
                    <div className="d-flex w-full items-center">
                      <div className="d-flex items-start flex-column">
                        <span className="theme-dark-text bold text-normal mr-auto">
                          #{appointment.appointmentId}
                        </span>
                        <div className="d-flex items-center mr-auto text-normal mb-2 main-text">
                          {new Intl.DateTimeFormat(i18n.language, {
                            dateStyle: "full",
                            timeStyle: "short",
                          })
                            .format(
                              moment(
                                `${appointment.dateAndTime.day}/${appointment.dateAndTime.month}/${appointment.dateAndTime.year} ${appointment.dateAndTime.hour}:${appointment.dateAndTime.minute}:${appointment.dateAndTime.second}`,
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            )
                            .replace(/^0+/, "")}
                        </div>
                      </div>

                      <Svg
                        name="arrow-right-small"
                        className="w-24 h-24 mt--3 mr--2 accent-text ml-auto"
                      />
                    </div>

                    <div
                      className="w-full gap-12 mt-2"
                      style={{ gridTemplateColumns: "repeat(3, 1fr)", display: "grid" }}
                    >
                      <div className="d-flex items-start flex-column text-normal">
                        <span className="theme-dark-text bold">
                          {t("appointmentHistory.amount")}
                        </span>
                        <span className="main-text">
                          {formatMoney(appointment.total, i18n.language)}
                        </span>
                      </div>

                      <div className="d-flex items-start flex-column text-normal">
                        <span className="theme-dark-text bold">
                          {t("appointmentHistory.status")}
                        </span>
                        <span className="main-text">{t(`appointment.${appointment.status}`)}</span>
                      </div>

                      {appointment.isRated && (
                        <div className="d-flex items-start flex-column text-normal">
                          <span className="bold theme-dark-text text-normal">
                            {t("appointmentHistory.rated")}
                          </span>

                          <Rating
                            className="d-flex justify-content-around text-normal"
                            initialRating={appointment.rating}
                            emptySymbol="fa fa-star-o theme-dark-text"
                            fullSymbol="fa fa-star theme-dark-text"
                            readonly={true}
                          />
                        </div>
                      )}
                    </div>

                    {appointment.corporateProfile && (
                      <div className="d-flex flex-column">
                        <span className="theme-dark-text bold text-normal mr-auto mt-3">
                          {t("corporate.corporateAccount")}
                        </span>

                        <span className="main-text text-normal mr-auto">
                          {appointment.corporateProfile.name}
                        </span>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>

          {loading && <Loader className="mt-2" title={t("common.loading")} />}
          {error && (
            <div
              className="error-alert mt-3 mx-auto"
              style={{ padding: "0.6rem", maxWidth: "100%" }}
            >
              {error}
            </div>
          )}

          {!error && !loading && appointments.length === 0 && (
            <div className="text-small sub-text text-center">{t("appointmentHistory.empty")}</div>
          )}

          {!error && !loading && nextPageToken && (
            <div className="d-flex items-center mx-auto mt-4">
              <Button
                outline
                color="primary"
                onClick={() => {
                  getHistory(nextPageToken);
                }}
              >
                {t("dashboard.moreAppointments")}
              </Button>
            </div>
          )}
        </div>
      </SideBar>

      <AppDetails
        sidebarGroup="app-history"
        isOpen={showApptDetailsSidebar && selectedAppointment != null}
        toggle={() => {
          setSelectedAppointment(null);
          setShowApptDetailsSidebar(false);
        }}
        onRated={(app, rating) => {
          setAppointments((prev) =>
            prev.map((a) => {
              if (a.appointmentId === app.id) {
                return Object.assign(a, { rating: rating, isRated: true });
              }
              return a;
            })
          );
        }}
        appointmentId={selectedAppointment ? selectedAppointment.appointmentId : null}
      />
    </React.Fragment>
  );
};

export default HistorySidebar;
