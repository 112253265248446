import React, { useEffect, useState, useRef, useCallback } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import moment from "moment";
import { Button, Input } from "reactstrap";
import CheckoutDetails from "../../components/CheckoutDetails";
import CancelModal from "../../components/Modals/CancelModal";
import Svg from "../../components/Svg/index.js";
import MapGL, { Marker, NavigationControl } from "react-map-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import Rating from "react-rating";
import PolylineOverlay from "./PolylineOverlay";
import Loader from "../../components/Loader";
import ChatComponent from "../../components/Chat/Chat";
import { useTranslation } from "react-i18next";
import {
  detectDefaultLanguage,
  formatDuration,
  formatFullAddress,
  parseError,
} from "../../helpers/common";
import { toast } from "react-toastify";
import { DASHBOARD_URL, EDIT_APPOINTMENT_URL } from "../../urls";

const OverviewAppointment = (props) => {
  const mapRef = useRef(null);
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [appointmentData, setAppointmentData] = useState(null);
  const [people, setPeople] = useState([]);
  const [address, setAddress] = useState(null);
  const [scheduledDate, setScheduledDate] = useState(null);
  const [notes, setNotes] = useState("");
  const [costData, setCostData] = useState(null);
  const [appointmentType, setAppointmentType] = useState("scheduled");
  const [cancelEnabled, setCancelEnabled] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [navigationRoute, setNavigationRoute] = useState(null);
  const [appointmentMarker, setAppointmentMarker] = useState(null);
  const [driverMarker, setDriverMarker] = useState(null);
  const [viewport, setViewport] = useState({
    latitude: 45.552411,
    longitude: -73.688447,
    zoom: 11,
  });

  useEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });

    fetchAppointment();
  }, [props.match.params.id]);

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      const language = new MapboxLanguage({
        defaultLanguage: detectDefaultLanguage().indexOf("fr") > -1 ? "fr" : "en",
      });
      map.addControl(language);
    }
  }, [mapRef]);

  React.useEffect(() => {
    if (
      appointmentData &&
      (appointmentData.status === "technician_in_transit" ||
        appointmentData.status === "technician_at_location")
    ) {
      const appointmentId = appointmentData.id;
      driverUpdatesFunc(appointmentId);
      window.driverTracker = setInterval(() => {
        driverUpdatesFunc(appointmentId);
      }, 20000);
    } else if (appointmentData && appointmentData.status !== "cancelled") {
      const appointmentId = appointmentData.id;
      window.appointmentTracker = setInterval(() => {
        appointmentUpdatesFunc(appointmentId);
      }, 60000);
    }

    return () => {
      clearTracking();
    };
  }, [appointmentData]);

  useEffect(() => {
    calculateDriveRoute();
  }, [driverMarker]);

  const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), []);

  const fetchAppointment = () => {
    setCancelEnabled(false);
    setLoading(false);
    setError(null);
    setAppointmentData(null);
    setPeople([]);
    setScheduledDate(null);
    setNotes("");
    setAddress(null);
    setCostData(null);
    setAppointmentType("scheduled");

    const appId = props.match.params.id;
    if (!appId) {
      return;
    }

    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/appointments/${appId}`)
      .then((res) => {
        setLoading(false);
        const app = res.data;
        if (app.status !== "technician_in_transit" && app.status !== "technician_at_location") {
          props.history.replace(EDIT_APPOINTMENT_URL.replace(":id", app.id));
          return;
        }

        initializeAppointment(res.data);
      })
      .catch((err) => {
        let error = parseError(err);
        setError(error);
        setLoading(false);
      });
  };

  const initializeAppointment = (appointment, fullInitialization = true) => {
    setAppointmentType(appointment.type);
    setCostData(appointment.cost);
    setNotes(appointment.patientNotes || "");

    const peopleList = appointment.appointmentPatients;

    peopleList.forEach((person) => {
      const services = person.appointmentItems.map((ai) => {
        const service = Object.assign({ key: ai.key }, ai.service);

        if (service.requisitionFormId) {
          service.requisitionFormFile = service.requisitionFormId;
        }

        if (service.insuranceFormId) {
          service.insuranceFormFile = service.insuranceFormId;
        }

        return service;
      });
      person.services = services;
    });

    setPeople(peopleList);
    setAddress(
      Object.assign(
        Object.assign(
          {
            fullAddress: formatFullAddress(appointment.address),
            provinceCode: appointment.address.province,
          },
          appointment.address
        ),
        appointment.location
      )
    );

    if (appointment.startTime) {
      const appTime = appointment.startTime;
      const scheduledDate = moment(
        `${appTime.day}/${appTime.month}/${appTime.year} ${appTime.hour}:${appTime.minute}:${appTime.second}`,
        "DD/MM/YYYY HH:mm:ss"
      );
      setScheduledDate(scheduledDate.toDate());
    } else {
      setScheduledDate(null);
    }
    setCancelEnabled(
      appointment.type === "scheduled" &&
        appointment.status !== "cancelled" &&
        appointment.status !== "ended" &&
        appointment.status !== "completed"
    );

    if (fullInitialization) {
      setViewport((prev) => Object.assign(prev, appointment.location));
      setAppointmentMarker(appointment.location);
    }

    setAppointmentData(appointment);
  };

  const clearTracking = () => {
    if (window.appointmentTracker) {
      clearInterval(window.appointmentTracker);
    }
    if (window.driverTracker) {
      clearInterval(window.driverTracker);
    }
  };

  const appointmentUpdatesFunc = (appointmentId) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/appointments/${appointmentId}`)
      .then((res) => {
        if (
          res.data.status !== "technician_in_transit" &&
          res.data.status !== "technician_at_location"
        ) {
          props.history.replace(EDIT_APPOINTMENT_URL.replace(":id", res.data.id));
          return;
        }

        initializeAppointment(res.data, false);
      })
      .catch((err) => {
        console.warn(err);
      });
  };

  const driverUpdatesFunc = (appointmentId) => {
    if (!appointmentId) {
      return;
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/appointments/${appointmentId}/technician-location`)
      .then((res) => {
        const techData = res.data;
        setDriverMarker(techData);
        calculateDriveRoute();

        setAppointmentData((prev) =>
          Object.assign(prev || {}, {
            status: techData.appointmentStatus,
            estimatedTimeMinutes: techData.estimatedTimeMinutes,
          })
        );
      })
      .catch((err) => {
        console.warn(err);
        clearTracking();

        if (err && err.response && err.response.status === 403) {
          appointmentUpdatesFunc(appointmentId);
        }
      });
  };

  const calculateDriveRoute = () => {
    if (
      driverMarker &&
      appointmentMarker &&
      appointmentData &&
      appointmentData.status === "technician_in_transit"
    ) {
      axios
        .get(
          `https://api.mapbox.com/directions/v5/mapbox/driving/${appointmentMarker.longitude},${appointmentMarker.latitude};${driverMarker.longitude},${driverMarker.latitude}?geometries=geojson&access_token=${process.env.REACT_APP_MAPBOX_KEY}`
        )
        .then((res) => {
          if (res.data && res.data.routes.length > 0) {
            let geometries = res.data.routes[0].geometry.coordinates;
            setNavigationRoute(geometries);
          }
        })
        .catch((err) => {
          console.warn(err);
        });
    } else {
      setNavigationRoute(null);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Avvy - {t("appointmentDetails.title")}</title>
      </Helmet>

      <main
        style={{ minHeight: "100vh", paddingBottom: 0 }}
        className="d-flex appointment-tracking-view"
      >
        <section className="d-flex appointment-tracking-map position-relative">
          <MapGL
            ref={mapRef}
            {...viewport}
            scrollZoom={true}
            width="100%"
            height="100%"
            maxPitch={85}
            mapStyle="mapbox://styles/mapbox/light-v10"
            onViewportChange={handleViewportChange}
            attributionControl={false}
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
          >
            <PolylineOverlay points={navigationRoute} />

            {appointmentMarker && (
              <Marker
                className="theme-color"
                latitude={appointmentMarker.latitude}
                longitude={appointmentMarker.longitude}
                offsetLeft={-21}
                offsetTop={-26.5}
              >
                <Svg name="location" className="w-48 theme-dark-text" />
              </Marker>
            )}

            {driverMarker && (
              <Marker
                latitude={driverMarker.latitude}
                longitude={driverMarker.longitude}
                offsetLeft={-10}
                offsetTop={-20}
              >
                <Svg name="Car" style={{ width: "20px", transform: "rotate(-180deg)" }} />
              </Marker>
            )}
          </MapGL>

          {appointmentData && appointmentData.technician && (
            <div
              className="d-flex items-center gap-12 position-absolute"
              style={{
                bottom: "24px",
                right: "24px",
                background: "#fff",
                borderRadius: "6px",
                padding: "12px 16px",
                minWidth: "320px",
                boxShadow:
                  "rgb(255, 255, 255) 0px 0px 0px 0px, rgb(224, 224, 224) 0px 0px 0px 1px, rgba(17, 17, 26, 0.1) 0px 4px 16px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px 0px, rgba(17, 17, 26, 0.1) 0px 16px 56px 0px",
              }}
            >
              {!appointmentData.technician.picture && (
                <Svg name="profile_empty" style={{ width: "40px", height: "40px" }} />
              )}
              {appointmentData.technician.picture && (
                <img
                  src={appointmentData.technician.picture.url}
                  style={{
                    width: "44px",
                    height: "44px",
                    borderRadius: "50%",
                    overflow: "hidden",
                    border: "1px solid (var--border-color)",
                    objectFit: "cover",
                  }}
                  alt="Technician Avatar"
                />
              )}
              <div
                className="d-flex flex-column items-start"
                style={{ marginRight: "24px", flexGrow: "1" }}
              >
                <div className="main-text" style={{ fontWeight: "500", fontSize: "16px" }}>
                  {appointmentData.technician.firstName} {appointmentData.technician.lastName}
                </div>
                <div className="d-flex items-end text-normal sub-text gap-12">
                  <div>{t("appointmentView.technician")}</div>
                  {appointmentData.hasOwnProperty("estimatedTimeMinutes") &&
                    appointmentData.estimatedTimeMinutes > 0 && (
                      <React.Fragment>
                        {" • "}
                        <div>{formatDuration(appointmentData.estimatedTimeMinutes)}</div>
                      </React.Fragment>
                    )}
                </div>
              </div>
              {process.env.REACT_APP_CHAT_ENABLED === "true" && appointmentData.chatRoomId && (
                <React.Fragment>
                  <div className="separator vertical" style={{ position: "initial" }}></div>
                  <ChatComponent chatRoomId={appointmentData.chatRoomId} />
                </React.Fragment>
              )}
            </div>
          )}
        </section>

        <section className="d-flex appointment-tracking-details">
          {loading && <Loader className="mt-8 w-full" title={t("common.loading")} />}
          {error && (
            <div
              className="error-alert w-full mt-3"
              style={{ padding: "0.6rem", maxWidth: "100%" }}
            >
              {error}
            </div>
          )}

          {appointmentData && (
            <div className="d-flex flex-column gap-16 appointment-tracking-checkout">
              <div className="d-flex flex-column gap-6">
                <div className="d-flex items-center">
                  <span className="sub-text mr-auto text-normal">{t("appointmentView.code")}</span>
                  <span className="main-text ml-auto bold text-normal">{appointmentData.id}</span>
                </div>

                <div className="d-flex flex-column gap-6">
                  <span className="sub-text mr-auto text-normal">
                    {t("appointmentView.status")}
                  </span>
                  <div
                    className="main-text bold text-normal gap-12 d-flex items-start"
                    style={{ lineHeight: "17px" }}
                  >
                    <Svg
                      name="icon_car_outline"
                      className="w-24 h-24 main-text"
                      style={{ flexShrink: "0" }}
                    />
                    {t(`appointmentView.${appointmentData.status}`)}
                  </div>
                </div>
              </div>

              <CheckoutDetails
                appointment={appointmentData}
                discountCode={appointmentData.discountCodeUsed}
                address={address}
                scheduledDate={scheduledDate}
                appointmentType={appointmentType}
                people={people}
                costData={costData}
                disabled={true}
                costUpdated={() => {}}
                {...props}
              />

              {appointmentData.technician && (
                <React.Fragment>
                  <hr className="m-0" />

                  <div className="d-flex flex-column gap-12">
                    <div className="bold theme-dark-text">{t("appointmentView.technician")}</div>

                    <div className="d-flex items-center gap-12">
                      {!appointmentData.technician.picture && (
                        <Svg name="profile_empty" style={{ width: "40px", height: "40px" }} />
                      )}
                      {appointmentData.technician.picture && (
                        <img
                          src={appointmentData.technician.picture.url}
                          style={{
                            width: "44px",
                            height: "44px",
                            borderRadius: "50%",
                            overflow: "hidden",
                            border: "1px solid (var--border-color)",
                            objectFit: "cover",
                          }}
                          alt="Technician Avatar"
                        />
                      )}
                      <div className="d-flex flex-column items-start">
                        <div className="main-text" style={{ fontWeight: "700", fontSize: "16px" }}>
                          {appointmentData.technician.firstName}{" "}
                          {appointmentData.technician.lastName}
                        </div>
                        <Rating
                          className="d-flex justify-content-around text-normal"
                          initialRating={appointmentData.technician.rating}
                          emptySymbol="fa fa-star-o theme-dark-text"
                          fullSymbol="fa fa-star theme-dark-text"
                          readonly={true}
                        />
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}

              <div className="d-flex flex-column gap-16">
                <section className="discount-section d-flex flex-column gap-6">
                  <span className="bold theme-dark-text">{t("appointmentDetails.notes")}</span>
                  <Input
                    onChange={(e) => {}}
                    type="textarea"
                    value={notes}
                    rows={3}
                    style={{ borderColor: "var(--border-color)", overflowY: "auto" }}
                    className="main-text"
                    readOnly={true}
                  />
                </section>
                {cancelEnabled && (
                  <Button
                    size="sm"
                    className="px-4 py-2 w-full"
                    color="danger"
                    onClick={() => setIsCancelModalOpen(true)}
                  >
                    {t("appointmentView.cancelBtn")}
                  </Button>
                )}
              </div>
            </div>
          )}

          {cancelEnabled && (
            <CancelModal
              appointment={appointmentData}
              isOpen={isCancelModalOpen}
              toggle={() => setIsCancelModalOpen(false)}
              onCancelled={() => {
                toast.dark(t("appointmentCancelled.text"), {
                  position: "top-right",
                  autoClose: 4000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });

                props.history.push(DASHBOARD_URL);
              }}
            />
          )}
        </section>
      </main>
    </React.Fragment>
  );
};
export default OverviewAppointment;
