import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import Loader from "../../components/Loader/index.js";
import { parseError } from "../../helpers/common.js";
import { useTranslation } from "react-i18next";

const TermsOfService = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation("common");
  const [termsOfService, setTermsOfService] = useState("");

  useEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });

    setLoading(true);
    setError(null);
    setTermsOfService("");

    axios
      .get(`${process.env.REACT_APP_API_URL}/platform/termsofservice`)
      .then((res) => {
        setLoading(false);
        setTermsOfService(res.data);
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        setError(error);
      });
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>Avvy - {t("tos.title")}</title>
      </Helmet>

      <div className="d-flex flex-column platform pb-5" style={{ width: "90%", maxWidth: "720px" }}>
        {loading && <Loader className="mt-5" title={t("common.loading")} />}
        {error && (
          <div className="error-alert mt-3 mx-auto" style={{ padding: "0.6rem", maxWidth: "100%" }}>
            {error}
          </div>
        )}

        <div
          className="d-flex flex-column"
          dangerouslySetInnerHTML={{ __html: termsOfService || "" }}
        ></div>
      </div>
    </React.Fragment>
  );
};

export default TermsOfService;
