import React from "react";

const RadioButton = ({ id, checked, handleChange, label, labelHtml, className, groupName }) => (
  <div className={`d-flex items-start gap-6 ${className || ""}`}>
    <input
      type="radio"
      name={groupName}
      className="flex-shrink-0"
      id={`radio-${id}`}
      checked={checked}
      onChange={(e) => {
        handleChange(id, e.target.checked);
      }}
    />
    {labelHtml ? (
      <label
        onClick={(e) => {
          handleChange(id, true);
        }}
        style={{ marginTop: "-2px" }}
        htmlFor={`radio-${id}`}
        dangerouslySetInnerHTML={{ __html: labelHtml || "" }}
      ></label>
    ) : (
      <label
        onClick={(e) => {
          handleChange(id, true);
        }}
        style={{ marginTop: "-2px" }}
        htmlFor={`radio-${id}`}
      >
        {label || ""}
      </label>
    )}
  </div>
);

export default RadioButton;
