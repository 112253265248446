import React, { useState, useEffect, forwardRef } from "react";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { Button } from "reactstrap";
import Loader from "../Loader";
import { parseError } from "../../helpers/common";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const StripeCard = forwardRef((props, ref) => {
  const { t } = useTranslation("common");
  const [cardNumberValid, setCardNumberValid] = useState(false);
  const [cardExpiryValid, setCardExpiryValid] = useState(false);
  const [cardCvcValid, setCardCvcValid] = useState(false);
  const [loaded, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkoutError, setCheckoutError] = useState(null);
  const [cvcError, setCvcError] = useState(null);
  const [expError, setExpError] = useState(null);

  const elementStyles = {
    base: {
      color: "var(--main-text-color)",
      fontFamily: '"Kanit", "Roboto", sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#4D4D4D60",
      },
    },
    invalid: {
      color: "var(--error-color)",
      iconColor: "var(--error-color)",
    },
    focus: {
      boxShadow: "0 0 0 0.15rem rgba(0,0,0,0.2)",
    },
  };

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    setLoad(true);
  }, []);

  const handleCardNumberChange = (ev) => {
    ev.error || !ev.complete || ev.empty ? setCardNumberValid(false) : setCardNumberValid(true);
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError(null);
  };

  const handleCardExpiryChange = (ev) => {
    ev.error || !ev.complete || ev.empty ? setCardExpiryValid(false) : setCardExpiryValid(true);
    ev.error ? setExpError(ev.error.message) : setExpError(null);
  };

  const handleCardCvcChange = (ev) => {
    ev.error || !ev.complete || ev.empty ? setCardCvcValid(false) : setCardCvcValid(true);
    ev.error ? setCvcError(ev.error.message) : setCvcError(null);
  };

  const handleSubmitSubNewCard = async (callback) => {
    setLoading(true);
    setError(null);

    if (!stripe || !elements || !cardNumberValid || !cardCvcValid || !cardExpiryValid) {
      setLoading(false);
      return;
    }

    const cardNumber = elements.getElement(CardNumberElement);
    const cardExpiry = elements.getElement(CardExpiryElement);
    const cardCvc = elements.getElement(CardCvcElement);
    const stripeToken = await stripe.createToken(cardNumber);

    if (!stripeToken.error) {
      const data = {
        stripeToken: stripeToken.token.id,
      };

      axios
        .post(`${process.env.REACT_APP_API_URL}/Account/payment-method`, data)
        .then((res) => {
          setLoading(false);
          if (props.onCardAdded) {
            props.onCardAdded(res.data);
          }
          cardNumber.clear();
          cardExpiry.clear();
          cardCvc.clear();
          showSuccess(t("paymentInfo.newCardAdded"));
          setCardNumberValid(false);
          setCardExpiryValid(false);
          setCardCvcValid(false);
        })
        .catch((err) => {
          let error = parseError(err);
          setError(error);
          setLoading(false);
        });
    } else {
      setLoading(false);
      setError(stripeToken.error.message);
    }
  };

  const showSuccess = (message) => {
    toast.dark(message, {
      position: "top-left",
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    props.toggle();
  };

  return (
    <React.Fragment>
      {loaded && (
        <div className="d-flex flex-column gap-12">
          <div className="avvy-card p-3">
            <CardNumberElement
              options={{
                style: elementStyles,
              }}
              onChange={handleCardNumberChange}
            />
          </div>
          {checkoutError && <small className="error-text">{checkoutError}</small>}
          <div className="d-flex gap-16">
            <div style={{ width: "50%" }}>
              <div className="avvy-card p-3">
                <CardExpiryElement
                  options={{
                    style: elementStyles,
                  }}
                  onChange={handleCardExpiryChange}
                />
              </div>
              {expError && <small className="error-text">{expError}</small>}
            </div>
            <div style={{ width: "50%" }}>
              <div className="avvy-card p-3">
                <CardCvcElement
                  options={{
                    style: elementStyles,
                  }}
                  onChange={handleCardCvcChange}
                />
              </div>
              {cvcError && <small className="error-text">{cvcError}</small>}
            </div>
          </div>
          <Button
            outline
            disabled={!cardNumberValid || !cardExpiryValid || !cardCvcValid || loading}
            className="w-100"
            onClick={() => handleSubmitSubNewCard()}
          >
            {t("paymentInfo.btn")}
          </Button>

          {loading && <Loader style={{ margin: "0px auto" }} title={t("common.loading")} />}

          {error && (
            <div
              className="error-alert"
              style={{ margin: "12px auto", padding: "0.6rem", maxWidth: "500px" }}
            >
              {error}
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
});

export default StripeCard;
