import React, { useState, useRef, useEffect } from "react";
import {
  Button,
  FormGroup,
  Label,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalHeader,
  DropdownToggle,
  DropdownItem,
  Dropdown,
  DropdownMenu,
} from "reactstrap";
import axios from "axios";
import ReactDatetime from "react-datetime";
import "moment/locale/fr";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";
import Svg from "../Svg";
import authProvider from "../../helpers/authProvider";
import { parseError, dateFormat } from "../../helpers/common";
import { toast } from "react-toastify";

const HealthDetailsModal = (props) => {
  const { t, i18n } = useTranslation("common");
  const inputFile = useRef(null);
  const [healthCardNumber, setHealthCardNumber] = useState("");
  const [cardNumberError, setCardNumberError] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [healthCareCardImage, setHealthCareCardImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [allergies, setAllergies] = useState("");
  const [province, setProvince] = useState("");
  const [provinces, setProvinces] = useState([]);
  const [provincesOpen, setProvincesOpen] = useState(false);
  const [provinceError, setProvinceError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setProvinceError(
      !province ? <small className="text-danger">{t("healthCare.provinceError")}</small> : null
    );

    setCardNumberError(
      !healthCardNumber ? (
        <small className="text-danger">{t("healthCare.healthCardNumberError")}</small>
      ) : null
    );
    getProvinces();
  }, []);

  const handleImageChange = (event) => {
    if (event.target.files.length > 0) {
      try {
        const file = event.target.files[0];
        const preview = URL.createObjectURL(file);
        setPreviewImage(preview);
        setHealthCareCardImage(file);
      } catch (err) {
        console.warn(err);
      }
    }
  };

  const removeImage = () => {
    if (previewImage) {
      try {
        URL.revokeObjectURL(previewImage);
      } catch {}
    }

    setPreviewImage(null);
    setHealthCareCardImage(null);

    if (inputFile && inputFile.current && inputFile.current.value) {
      try {
        inputFile.current.value = "";
      } catch (err) {}
    }
  };

  const getProvinces = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/platform/provinces`)
      .then((res) => {
        setProvinces(res.data);
      })
      .catch((err) => {
        console.error(err);
        setProvinces([]);
      });
  };

  const onSaveHealthData = async () => {
    if (!healthCardNumber || !province || !healthCareCardImage) {
      return;
    }

    var currentUser = authProvider.getUser();

    let updateDetails = {
      id: currentUser.id,
      healthCareCard: {
        cardNumber: healthCardNumber,
        expiryDate:
          expiryDate != null
            ? {
                year: expiryDate.getFullYear(),
                month: expiryDate.getMonth() + 1,
                day: expiryDate.getDate(),
              }
            : null,
        picture: {
          fileName: null,
        },
        province: province.key,
      },
      issuesAllergies: allergies || null,
    };

    let uploadCompleted = false;
    setLoading(true);
    setError(null);

    try {
      let formData = new FormData();
      formData.append("file", healthCareCardImage, healthCareCardImage.name);

      let fileRes = await axios.post(
        `${process.env.REACT_APP_API_URL}/file/upload/healthcard`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      updateDetails.healthCareCard.picture = {
        fileName: fileRes.data,
      };

      uploadCompleted = true;
    } catch (err) {
      let error = parseError(err);
      setError(error);
    }

    if (!uploadCompleted) {
      setLoading(false);
      return;
    }

    axios
      .put(`${process.env.REACT_APP_API_URL}/account`, updateDetails)
      .then((res) => {
        authProvider.setUser(res.data);
        toast.dark(t("personalInfo.updatedText"), {
          position: "top-left",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setLoading(false);

        if (props.onConfirmed) {
          props.onConfirmed();
        }
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        setError(error);
      });
  };

  return (
    <div>
      <Modal
        className="modal-dialog-centered"
        style={{ maxWidth: 600 }}
        isOpen={props.isOpen}
        toggle={() => props.toggle()}
      >
        <ModalHeader
          close={
            <button className="close" onClick={() => props.toggle()}>
              <Svg className="w-24 h-24 sub-text" name="icon_cancel" />
            </button>
          }
          className="pb-0"
          style={{ borderBottom: 0 }}
        >
          <div className="text-muted mt-0 mx-auto">
            <h6 className="mb-0">{t("healthCare.title")}</h6>
          </div>
        </ModalHeader>
        <div className="modal-body pt-2" style={{ padding: "1.25rem" }}>
          <div className="d-flex flex-column">
            <p className="m-0 text-small sub-text">{t("healthCare.noHealthDataOnAccount")}</p>
            <hr style={{ marginLeft: "-40px", marginRight: "-40px" }} />

            <div className="d-flex flex-column gap-16 w-full">
              <FormGroup>
                <Label className="bold-blue">{t("healthCare.provinceLabel")}</Label>
                <InputGroup className="input-group-alternative">
                  <Dropdown
                    disabled={loading}
                    isOpen={provincesOpen}
                    toggle={() => setProvincesOpen((prev) => !prev)}
                    direction="down"
                    style={{ width: "100%", height: "44px" }}
                  >
                    <Svg
                      className={provincesOpen ? "flip-y w-24 h-24" : "w-24 h-24"}
                      style={{ position: "absolute", right: 10, top: 10, pointerEvents: "none" }}
                      name="icon_down"
                    />
                    <DropdownToggle
                      tag="div"
                      data-toggle="dropdown"
                      style={{ cursor: "pointer", padding: "9.5px 12px", width: "100%" }}
                      aria-expanded={provincesOpen}
                    >
                      {province ? province.value : t("healthCare.provincePlaceholder")}
                    </DropdownToggle>
                    <DropdownMenu style={{ width: "100%", marginTop: 10, padding: "10px" }}>
                      {provinces &&
                        provinces.map((item) => {
                          return (
                            <DropdownItem
                              key={item.key}
                              onClick={() => {
                                setProvince(item);
                                setProvinceError(null);
                              }}
                            >
                              {item.value}
                            </DropdownItem>
                          );
                        })}
                    </DropdownMenu>
                  </Dropdown>
                </InputGroup>
                {provinceError}
              </FormGroup>

              <div className="d-flex items-start gap-24">
                <FormGroup className="w-full">
                  <Label className="theme-dark-text bold">
                    {t("healthCare.healthCardNumberLabel")}
                  </Label>
                  <InputGroup className="input-group-alternative">
                    <Input
                      disabled={loading}
                      value={healthCardNumber}
                      onChange={(event) => {
                        setHealthCardNumber(event.target.value);
                      }}
                      onBlur={(event) => {
                        if (event.target.value === "") {
                          setCardNumberError(
                            <small className="text-danger">
                              {t("healthCare.healthCardNumberError")}
                            </small>
                          );
                        } else {
                          setCardNumberError(null);
                        }
                      }}
                      placeholder={t("healthCare.healthCardNumberPlaceholder")}
                      type="text"
                    />
                  </InputGroup>
                  {cardNumberError}
                </FormGroup>

                <FormGroup className="w-full">
                  <Label className="theme-dark-text bold">{t("healthCare.expiryDateLabel")}</Label>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>

                    <ReactDatetime
                      locale={i18n.language}
                      dateFormat={dateFormat}
                      value={expiryDate}
                      onChange={(e) => {
                        if (e && typeof e.toDate === "function") {
                          setExpiryDate(e.toDate());
                        } else {
                          setExpiryDate(null);
                        }
                      }}
                      inputProps={{
                        disabled: loading,
                        placeholder: t("healthCare.expiryDatePlaceholder"),
                        className: "dob-readonly-input form-control",
                      }}
                      timeFormat={false}
                      closeOnSelect={true}
                    />
                  </InputGroup>
                  <p
                    className="sub-text text-small"
                    style={{ marginTop: "6px", marginBottom: 0, fontWeight: 400 }}
                  >
                    {t("personalInfo.healthCardExpiryNote")}
                  </p>
                </FormGroup>
              </div>

              {healthCareCardImage && previewImage ? (
                <div
                  style={{
                    overflow: "hidden",
                    position: "relative",
                    minHeight: "160px",
                    border: "1px solid var(--border-color)",
                    borderRadius: "6px",
                    background: "#f8f8f8",
                  }}
                  className="upload d-flex flex-column"
                >
                  <Button
                    disabled={loading}
                    onClick={() => removeImage()}
                    color="link"
                    style={{
                      margin: 0,
                      padding: 0,
                      color: "var(-error-color)",
                      background: "white",
                      position: "absolute",
                      right: "6px",
                      top: "6px",
                    }}
                  >
                    <Svg name="icon_cancel" style={{ width: "24px", height: "24px" }} />
                  </Button>

                  <img
                    style={{
                      maxWidth: "100%",
                      maxHeight: "240px",
                      objectPosition: "top",
                      objectFit: "contain",
                    }}
                    alt="Card Preview"
                    src={previewImage}
                  />
                </div>
              ) : (
                <div className="upload-file">
                  <div
                    className="upload-file-box"
                    style={{ cursor: "pointer", height: "240px" }}
                    onClick={() => inputFile.current.click()}
                  >
                    <div className="d-flex flex-column items-center gap-6">
                      <Svg
                        name="icon_camera"
                        className="theme-dark-text"
                        style={{ width: "32px", height: "32px" }}
                      />
                      <span className="theme-dark-text">{t("requisitionForm.text")}</span>
                    </div>
                  </div>
                </div>
              )}

              <input
                id="signup-healthcard-image-upload"
                type="file"
                accept=".jpeg,.jpg,.png"
                ref={inputFile}
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
            </div>

            <hr style={{ marginLeft: "-40px", marginRight: "-40px" }} />

            <FormGroup className="mb-3" style={{ width: "100%" }}>
              <Label className="theme-dark-text bold">{t("allergies.subText")}</Label>
              <Input
                disabled={loading}
                value={allergies}
                id="exampleFormControlTextarea1"
                placeholder={t("allergies.allergiesPlaceholder")}
                onChange={(event) => {
                  setAllergies(event.target.value);
                }}
                rows={3}
                type="textarea"
              />
            </FormGroup>

            {loading && <Loader title={t("common.loading")} />}
            {error && (
              <div className="error-alert mx-auto" style={{ padding: "0.6rem", maxWidth: "100%" }}>
                {error}
              </div>
            )}

            <hr style={{ marginLeft: "-40px", marginRight: "-40px" }} />

            <div className="d-flex items-center w-full gap-16">
              <Button
                className="m-0 w-full"
                color="primary"
                outline
                disabled={loading}
                type="button"
                onClick={() => {
                  if (props.toggle) {
                    props.toggle();
                  }
                }}
              >
                {t("dashboard.cancelBtn")}
              </Button>

              <Button
                className="m-0 w-full"
                color="primary"
                disabled={
                  loading ||
                  !healthCareCardImage ||
                  !province ||
                  !healthCardNumber ||
                  cardNumberError != null
                }
                type="button"
                onClick={() => {
                  onSaveHealthData();
                }}
              >
                {t("editPerson.btnSave")}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default HealthDetailsModal;
