import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Button, FormGroup, Input, InputGroup, Label } from "reactstrap";
import Loader from "../../../components/Loader/index.js";
import Svg from "../../../components/Svg";
import "react-phone-number-input/style.css";
import { Link, Redirect } from "react-router-dom";
import { detectDefaultLanguage, parseError } from "../../../helpers/common";
import { AUTH_SIGN_UP_EMAIL_VERIFICATION, AUTH_SIGN_UP_PERSONAL_INFO_URL } from "../../../urls";
import { useTranslation } from "react-i18next";

const SignUpPassword = (props) => {
  const { t } = useTranslation("common");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmType, setConfirmType] = useState("password");
  const [passwordType, setPasswordType] = useState("password");
  const [passwordError, setPasswordError] = useState(null);
  const [passwordConfirmError, setPasswordConfirmError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!window.signupState) {
      return;
    }

    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });

    setPassword(window.signupState.password || "");
    setConfirmPassword(window.signupState.confirmPassword || "");
  }, []);

  const showHide = (e, type) => {
    e.preventDefault();
    e.stopPropagation();

    if (type === "password") {
      if (passwordType === "password") {
        setPasswordType("text");
      } else {
        setPasswordType("password");
      }
    } else if (type === "confirm") {
      if (confirmType === "password") {
        setConfirmType("text");
      } else {
        setConfirmType("password");
      }
    }
  };

  const checkPassword = () => {
    if (
      confirmPassword &&
      password &&
      password.match(
        "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
      ) &&
      password.match(/[a-z]/) &&
      password.match(/[A-Z]/) &&
      password.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g) &&
      password.match(/[0-9]/) &&
      confirmPassword === password
    ) {
      setPasswordError(null);
      setPasswordConfirmError(null);
    } else if (
      (password &&
        !password.match(
          "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})"
        )) ||
      !password.match(/[a-z]/) ||
      !password.match(/[A-Z]/) ||
      !password.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g) ||
      !password.match(/[0-9]/)
    ) {
      setPasswordError(<small className="text-danger">{t("password.passwordError")}</small>);
    } else {
      setPasswordConfirmError(
        <small className="text-danger">{t("password.confirmPasswordError")}</small>
      );
    }
  };

  const createAccount = () => {
    let formData = new FormData();

    const userData = {
      email: window.signupState.email,
      phone: window.signupState.phone,
      password: password,
      passwordConfirmation: confirmPassword,
      firstName: window.signupState.firstName,
      lastName: window.signupState.lastName,
      gender: null,
      dateOfBirth: {
        year: window.signupState.dob.getFullYear(),
        month: window.signupState.dob.getMonth() + 1,
        day: window.signupState.dob.getDate(),
      },
      address: {
        province: window.signupState.address.provinceCode,
        city: window.signupState.address.city,
        street: window.signupState.address.street,
        postalCode: window.signupState.address.postalCode,
        unitNumber: window.signupState.address.unitNumber,
      },
      location: {
        latitude: window.signupState.address.latitude,
        longitude: window.signupState.address.longitude,
      },
      language: detectDefaultLanguage(),
      insurance: {
        provider: window.signupState.providerName,
        policyNumber: window.signupState.policyNumber,
      },
    };

    formData.append("Data", JSON.stringify(userData));

    setLoading(true);
    setError(null);

    axios
      .post(`${process.env.REACT_APP_API_URL}/account/register`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((res) => {
        setLoading(false);
        window.signupState.password = password;
        window.signupState.confirmPassword = confirmPassword;
        props.history.push(AUTH_SIGN_UP_EMAIL_VERIFICATION);
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        setError(error);
      });
  };

  if (!window.signupState) {
    return <Redirect to={AUTH_SIGN_UP_PERSONAL_INFO_URL} />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Avvy - {t("password.title")}</title>
      </Helmet>
      <div className="d-flex flex-column" style={{ width: "90%", maxWidth: "480px" }}>
        <Link to={AUTH_SIGN_UP_PERSONAL_INFO_URL}>
          <Svg className="page-back-arrow" name="arrow-left" />
        </Link>

        <h2 className="mb-3">{t("password.passwordPlaceholder")}</h2>

        <div className="d-flex flex-column gap-16 w-full">
          <div className="main-text text-normal">{t("password.passwordHint")}</div>
          <ul className="main-text text-normal">
            <li>{t("password.passwordHint1")}</li>
            <li>{t("password.passwordHint2")}</li>
            <li>{t("password.passwordHint3")}</li>
            <li>{t("password.passwordHint4")}</li>
          </ul>
          <FormGroup>
            <Label className="theme-dark-text bold">{t("password.password")}*</Label>
            <InputGroup className="input-group-alternative">
              <Input
                placeholder={t("password.passwordPlaceholder")}
                type={passwordType}
                value={password}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
                onBlur={(event) => {
                  if (event.target.value.length < 8) {
                    setPasswordError(
                      <small className="text-danger">{t("password.passwordError")})</small>
                    );
                  } else {
                    checkPassword();
                  }
                }}
              />
              {passwordType === "text" ? (
                <Svg
                  className="password-eye-icon"
                  name="eye-off"
                  style={{ top: 10 }}
                  onClick={(e) => showHide(e, "password")}
                />
              ) : (
                <Svg
                  className="password-eye-icon"
                  name="eye-on"
                  style={{ top: 10 }}
                  onClick={(e) => showHide(e, "password")}
                />
              )}
            </InputGroup>
            {passwordError}
          </FormGroup>

          <FormGroup>
            <Label className="theme-dark-text bold">{t("password.confirmPassword")}*</Label>
            <InputGroup className="input-group-alternative" style={{ position: "relative" }}>
              <Input
                placeholder={t("password.confirmPasswordPlaceholder")}
                type={confirmType}
                value={confirmPassword}
                onChange={(event) => {
                  setConfirmPassword(event.target.value);
                }}
                onBlur={(event) => {
                  if (!event.target.value) {
                    setPasswordConfirmError(
                      <small className="text-danger">{t("password.confirmPasswordError")}</small>
                    );
                  } else {
                    checkPassword();
                  }
                }}
              />
              {confirmType === "text" ? (
                <Svg
                  className="password-eye-icon"
                  name="eye-off"
                  style={{ top: 10 }}
                  onClick={(e) => showHide(e, "confirm")}
                />
              ) : (
                <Svg
                  className="password-eye-icon"
                  name="eye-on"
                  style={{ top: 10 }}
                  onClick={(e) => showHide(e, "confirm")}
                />
              )}
            </InputGroup>
            {passwordConfirmError}
          </FormGroup>

          <Button
            disabled={
              !password ||
              passwordError != null ||
              !confirmPassword ||
              passwordConfirmError != null ||
              password !== confirmPassword ||
              loading
            }
            className="w-full mt-4 btn-accent"
            onClick={createAccount}
          >
            {t("welcome.createAccount")}
          </Button>

          {loading && <Loader className="mt-4" title={t("common.loading")} />}
          {error && (
            <div
              className="error-alert mt-3 mx-auto"
              style={{ padding: "0.6rem", maxWidth: "100%" }}
            >
              {error}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignUpPassword;
