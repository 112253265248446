import React, { useState, useEffect } from "react";
import { Button, FormGroup, Label, Input } from "reactstrap";
import SideBar from ".";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { parseError } from "../../helpers/common";
import Svg from "../Svg";
import Loader from "../Loader";
import axios from "axios";

const ChangePasswordSideBar = (props) => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [oldPassType, setOldPassType] = useState("password");
  const [newPassType, setNewPassType] = useState("password");
  const [confirmType, setConfirmType] = useState("password");

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmErrorMsg, setConfirmErrorMsg] = useState(null);
  const [passErrorMessage, setPassErrorMessage] = useState(null);
  const [charNumberValid, setCharNumberValid] = useState(true);
  const [specialCharacterValid, setSpecialCharacterValid] = useState(true);
  const [uppercaseValid, setUppercaseValid] = useState(true);
  const [numberValid, setNumberValid] = useState(true);

  useEffect(() => {
    if (!props.isOpen) {
      resetState();
    }
  }, [props.isOpen]);

  useEffect(() => {
    if ((charNumberValid && uppercaseValid && numberValid, specialCharacterValid)) {
      setPassErrorMessage(null);
    } else {
      setPassErrorMessage(t("password.passwordError"));
    }
  }, [newPassword, charNumberValid, uppercaseValid, numberValid, passErrorMessage, specialCharacterValid]);

  useEffect(() => {
    handleConfirmPassword();
  }, [confirmPassword, newPassword]);

  const onChangePassword = () => {
    setLoading(true);
    setError(null);

    axios
      .post(`${process.env.REACT_APP_API_URL}/account/changepassword`, {
        currentPassword: oldPassword,
        newPassword: newPassword,
        newPasswordConfirmation: confirmPassword,
      })
      .then((res) => {
        setLoading(false);
        toast.dark(t("changePasswordSuccessMsg.title"), {
          position: "top-left",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        props.toggle();
      })
      .catch((err) => {
        setLoading(false);
        const error = parseError(err);
        setError(error);
      });
  };

  const showHide = (e, type) => {
    e.preventDefault();
    e.stopPropagation();

    if (type === "old") {
      setOldPassType(oldPassType === "input" ? "password" : "input");
    } else if (type === "new") {
      setNewPassType(newPassType === "input" ? "password" : "input");
    } else if (type === "confirm") {
      setConfirmType(confirmType === "input" ? "password" : "input");
    }
  };

  const handleConfirmPassword = () => {
    if (confirmPassword !== newPassword) {
      setConfirmErrorMsg(t("password.confirmPasswordError"));
    } else {
      setConfirmErrorMsg(null);
    }
  };

  const checkPasswordLength = (newPassword) => {
    if (newPassword.length >= 8) {
      setCharNumberValid(true);
    } else {
      setCharNumberValid(false);
    }
  };

  const checkUppercase = (newPassword) => {
    const pattern = /[A-Z]/;
    if (pattern.test(newPassword)) {
      setUppercaseValid(true);
    } else {
      setUppercaseValid(false);
    }
  };

  const checkNumber = (newPassword) => {
    const pattern = /[0-9]/;
    if (pattern.test(newPassword)) {
      setNumberValid(true);
    } else {
      setNumberValid(false);
    }
  };

  const checkSpecialCharacter = (newPassword) => {
    const pattern = /[!@#$ %^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    if (pattern.test(newPassword)) {
      setSpecialCharacterValid(true);
    } else {
      setSpecialCharacterValid(false);
    }
  };

  const handlePasswordChange = (e) => {
    checkPasswordLength(e.target.value);
    checkUppercase(e.target.value);
    checkNumber(e.target.value);
    checkSpecialCharacter(e.target.value);
  };

  const resetState = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setConfirmErrorMsg(null);
    setPassErrorMessage(null);
    setOldPassType("password");
    setNewPassType("password");
    setConfirmType("password");
  };

  const Footer = () => {
    return (
      <div className="d-flex w-full items-center">
        <Button
          size="sm"
          className="px-5 py-2 ml-auto btn-accent"
          onClick={() => {
            onChangePassword();
          }}
          disabled={
            loading ||
            !newPassword ||
            !oldPassword ||
            !confirmPassword ||
            newPassword !== confirmPassword ||
            passErrorMessage != null ||
            confirmErrorMsg != null
          }
        >
          {t("changePassword.changeBtn")}
        </Button>
      </div>
    );
  };

  return (
    <SideBar {...props} title={t("changePassword.title")} footer={<Footer />}>
      <div className="change-password">
        <div className="d-flex flex-column gap-16">
          <FormGroup style={{ position: "relative" }}>
            <Label className="bold-blue" for="oldPassword">
              {t("changePassword.oldPassword")}
            </Label>

            <Input
              name="oldPassword"
              id="oldPassword"
              disabled={loading}
              placeholder={t("changePassword.oldPasswordPlaceholder")}
              type={oldPassType}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required
            />

            {oldPassType === "input" ? (
              <Svg style={{ top: "46px" }} className="password-eye-icon" name="eye-off" onClick={(e) => showHide(e, "old")} />
            ) : (
              <Svg style={{ top: "46px" }} className="password-eye-icon" name="eye-on" onClick={(e) => showHide(e, "old")} />
            )}
          </FormGroup>

          <FormGroup style={{ position: "relative" }}>
            <Label className="bold-blue" for="newPassword">
              {t("changePassword.newPassword")}
            </Label>
            <Input
              disabled={loading}
              name="newPassword"
              id="newPassword"
              placeholder={t("changePassword.newPasswordPlaceholder")}
              type={newPassType}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              onBlur={(e) => handlePasswordChange(e)}
              required
            />

            {newPassType === "input" ? (
              <Svg style={{ top: "46px" }} className="password-eye-icon" name="eye-off" onClick={(e) => showHide(e, "new")} />
            ) : (
              <Svg style={{ top: "46px" }} className="password-eye-icon" name="eye-on" onClick={(e) => showHide(e, "new")} />
            )}

            {passErrorMessage && <span className="text-normal error-text mt-1">{passErrorMessage}</span>}
          </FormGroup>

          <FormGroup style={{ position: "relative" }} className="mb-4">
            <Label className="bold-blue" for="confirmPassword">
              {t("changePassword.confirmPass")}
            </Label>
            <Input
              disabled={loading}
              name="confirmPassword"
              id="confirmPassword"
              placeholder={t("changePassword.confirmPasswordPlaceholder")}
              type={confirmType}
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              required
            />

            {confirmType === "input" ? (
              <Svg style={{ top: "46px" }} className="password-eye-icon" name="eye-off" onClick={(e) => showHide(e, "confirm")} />
            ) : (
              <Svg style={{ top: "46px" }} className="password-eye-icon" name="eye-on" onClick={(e) => showHide(e, "confirm")} />
            )}

            {confirmErrorMsg && <span className="text-normal error-text mt-1">{confirmErrorMsg}</span>}
          </FormGroup>
        </div>

        {loading && <Loader className="mt-3" title={t("common.loading")} />}
        {error && (
          <div className="error-alert mt-3 mx-auto" style={{ padding: "0.6rem", maxWidth: "100%" }}>
            {error}
          </div>
        )}
      </div>
    </SideBar>
  );
};

export default ChangePasswordSideBar;
