import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import Loader from "../../components/Loader";
import Svg from "../../components/Svg";
import { Link } from "react-router-dom";
import { Button, FormGroup, Input, Label, InputGroup } from "reactstrap";
import { AUTH_RESET_PASSWORD_URL, AUTH_LOGIN_URL } from "../../urls";
import { useTranslation } from "react-i18next";
import { parseError } from "../../helpers/common";

const ForgotPassword = (props) => {
  const { t } = useTranslation("common");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(false);
    setError(null);
  }, []);

  const onSendCode = () => {
    setLoading(true);
    setError(null);

    axios
      .post(`${process.env.REACT_APP_API_URL}/account/forgotpassword`, JSON.stringify(email), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        setLoading(false);
        props.history.push({
          pathname: AUTH_RESET_PASSWORD_URL,
          search: "?email=" + encodeURIComponent(email),
        });
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        setError(error);
      });
  };

  const checkValidEmail = (e) => {
    if (e.target.value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) == null || !e.target.value) {
      setEmailError(<small className="text-danger">{t("forgotPassword.error")}</small>);
    } else {
      setEmailError(null);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Avvy - {t("forgotPassword.title")}</title>
      </Helmet>

      <div
        className="d-flex flex-column items-start gap-6"
        style={{ width: "90%", maxWidth: "420px" }}
      >
        <Link to={AUTH_LOGIN_URL} className="mb-4">
          <Svg className="page-back-arrow" name="arrow-left" />
        </Link>

        <h2 className="m-0 main-text">{t("forgotPassword.title")}</h2>
        <span className="main-text text-normal">{t("forgotPassword.text")}</span>

        <FormGroup className="mt-2 w-full">
          <Label className="theme-dark-text bold">{t("forgotPassword.email")}</Label>
          <InputGroup className="input-group-alternative main-text">
            <Input
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              onBlur={(event) => checkValidEmail(event)}
              placeholder={t("forgotPassword.emailPlaceholder")}
              type="email"
            />
          </InputGroup>
          {emailError}
        </FormGroup>

        <Button
          className="mt-3 btn-accent"
          disabled={!email || emailError || loading}
          block
          onClick={() => onSendCode()}
          type="button"
        >
          {t("forgotPassword.btn")}
        </Button>

        {loading && <Loader className="align-self-center mt-2" title={t("common.loading")} />}

        {error && (
          <div className="error-alert mt-3 mx-auto" style={{ padding: "0.6rem", maxWidth: "100%" }}>
            {error}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ForgotPassword;
