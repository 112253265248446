import React, { useEffect, useState } from "react";
import SideBar from ".";
import axios from "axios";
import { Button, FormGroup, InputGroup, Input, Label } from "reactstrap";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";
import { parseError } from "../../helpers/common";
import CorporateProfileView from "../../views/Corporate/ProfileView";
import authProvider from "../../helpers/authProvider";

const CorporateProfileSidebar = (props) => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentProfile, setCurrentProfile] = useState(null);
  const [hasCorporateProfile, setHasCorporateProfile] = useState(false);
  const [publicProfile, setPublicProfile] = useState(null);
  const [corporateCode, setCorporateCode] = useState("");

  useEffect(() => {
    if (props.isOpen) {
      getUserCorporateProfile();
      let corporateItem = sessionStorage.getItem("corporate-code-link");
      if (corporateItem !== null) {
        sessionStorage.removeItem("corporate-code-link");
        setCorporateCode(corporateItem);
        loadPublicProfile(corporateItem);
      }
    } else {
      setCurrentProfile(null);
      setPublicProfile(null);
      setError(null);
      setLoading(false);
      setCorporateCode("");
      setHasCorporateProfile(false);
    }
  }, [props.isOpen]);

  const resetView = () => {
    setCurrentProfile(null);
    setPublicProfile(null);
    setError(null);
    setLoading(false);
    setHasCorporateProfile(false);
    getUserCorporateProfile();
  };

  const getUserCorporateProfile = () => {
    setLoading(true);
    setError(null);
    setCurrentProfile(null);

    axios
      .get(`${process.env.REACT_APP_API_URL}/account/corporate-profile`)
      .then((res) => {
        setLoading(false);
        if (res.data.status !== 204 && res.data) {
          setCurrentProfile(res.data);
          setHasCorporateProfile(true);
        }

        if (res.data.status === 204) {
          setHasCorporateProfile(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        setError(error);
      });
  };

  const loadPublicProfile = (code = null) => {
    setLoading(true);
    setError(null);

    axios
      .get(`${process.env.REACT_APP_API_URL}/corporate/public-profile/${code || corporateCode}`)
      .then((res) => {
        setPublicProfile(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        setError(error);
      });
  };

  const linkAccount = () => {
    setLoading(true);
    setError(null);

    axios
      .patch(`${process.env.REACT_APP_API_URL}/account/corporate-profile/${corporateCode}/link`)
      .then((res) => {
        setPublicProfile(null);
        setCurrentProfile(res.data);
        setHasCorporateProfile(true);
        setLoading(false);
        setCorporateCode("");

        let user = authProvider.getUser();
        Object.assign(user, { hasCorporateProfile: true });
        authProvider.setUser(user);

        window.dispatchEvent(new CustomEvent("profileUpdated"));
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        setError(error);
      });
  };

  const unlinkAccount = () => {
    setLoading(true);
    setError(null);

    axios
      .delete(`${process.env.REACT_APP_API_URL}/account/corporate-profile/unlink`)
      .then((res) => {
        setPublicProfile(null);
        setCurrentProfile(null);
        setHasCorporateProfile(false);
        setLoading(false);

        let user = authProvider.getUser();
        Object.assign(user, { hasCorporateProfile: false });
        authProvider.setUser(user);

        window.dispatchEvent(new CustomEvent("profileUpdated"));

        localStorage.setItem("profileType", "personal");
        window.dispatchEvent(new CustomEvent("profileTypeChanged"));
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        setError(error);
      });
  };

  return (
    <React.Fragment>
      <SideBar title={t("corporate.pageTitle")} {...props}>
        <div className="d-flex flex-column gap-16">
          {!hasCorporateProfile && publicProfile === null && !loading && !error && (
            <div className="d-flex flex-column gap-16">
              <FormGroup>
                <Label className="bold-blue">{t("corporate.corporateCode")}</Label>
                <InputGroup className="input-group-alternative">
                  <Input
                    required
                    value={corporateCode}
                    maxLength={20}
                    onChange={(e) => setCorporateCode(e.target.value)}
                    placeholder={t("corporate.corporateCodePlaceholder")}
                    type="text"
                  />
                </InputGroup>
              </FormGroup>

              <span className="sub-text text-normal">{t("corporate.noProfileInstructions")}</span>

              <Button
                disabled={corporateCode.length === 0}
                onClick={() => {
                  loadPublicProfile();
                }}
                className="text-center px-6 ml-auto btn-accent"
              >
                {t("forgotPassword.btn")}
              </Button>
            </div>
          )}

          {publicProfile &&
            (currentProfile === null || currentProfile.name !== publicProfile.name) &&
            !loading &&
            !error && (
              <div className="d-flex flex-column gap-16">
                <CorporateProfileView profile={publicProfile} />
                <p
                  className="corporate-link-note text-normal m-0"
                  dangerouslySetInnerHTML={{
                    __html: t("corporate.linkNote").replaceAll("{0}", publicProfile.name),
                  }}
                ></p>

                <div className="d-flex gap-10 ml-auto">
                  <Button
                    onClick={() => {
                      setPublicProfile(null);
                    }}
                    color="primary"
                    outline
                    className="text-center flex-shrink-0 px-4"
                  >
                    {hasCorporateProfile
                      ? t("appointmentDetails.btnCancel")
                      : t("cancelAppointment.goBackBtn")}
                  </Button>
                  <Button
                    onClick={() => {
                      linkAccount();
                    }}
                    className="text-center flex-shrink-0 px-4 btn-accent"
                  >
                    {t("corporate.linkBtnText")}
                  </Button>
                </div>
              </div>
            )}

          {publicProfile &&
            currentProfile &&
            !loading &&
            currentProfile.name !== publicProfile.name && (
              <div
                className="separator horizontal position-relative"
                style={{ margin: "16px 0" }}
              ></div>
            )}

          {currentProfile !== null && !loading && !error && (
            <div className="d-flex flex-column gap-16">
              <CorporateProfileView profile={currentProfile} />
              <p
                className="corporate-link-note m-0 text-normal"
                dangerouslySetInnerHTML={{
                  __html: t("corporate.linkedNote").replaceAll("{0}", currentProfile.name),
                }}
              ></p>

              <Button
                onClick={() => {
                  unlinkAccount();
                }}
                color="danger"
                className="text-center px-4 ml-auto"
              >
                {t("corporate.unlinkBtnText")}
              </Button>
            </div>
          )}

          {loading && <Loader className="mt-4" title={t("common.loading")} />}
          {error && (
            <React.Fragment>
              <div
                className="error-alert mt-3 mx-auto"
                style={{ padding: "0.6rem", maxWidth: "100%" }}
              >
                {error}
              </div>

              <Button
                onClick={() => {
                  resetView();
                }}
                color="primary"
                outline
                className="text-center flex-shrink-0 px-4"
              >
                {t("cancelAppointment.goBackBtn")}
              </Button>
            </React.Fragment>
          )}
        </div>
      </SideBar>
    </React.Fragment>
  );
};

export default CorporateProfileSidebar;
