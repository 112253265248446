import React, { useEffect, useState } from "react";
import SideBar from ".";
import Loader from "../Loader";
import { FormGroup, CustomInput, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { changeLanguage, detectDefaultLanguage, parseError } from "../../helpers/common";
import authProvider from "../../helpers/authProvider";

const LanguagesSidebar = (props) => {
  const { t, i18n } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [error, setError] = useState(null);

  const onChangeLanguage = () => {
    let data = { lang: selectedLanguage };
    setLoading(true);
    setError(null);

    axios
      .put(`${process.env.REACT_APP_API_URL}/account/language`, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        const user = authProvider.getUser();
        user.language = selectedLanguage;
        authProvider.setUser(user);
        changeLanguage(i18n, t, selectedLanguage);
        setLoading(false);
        props.toggle();
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        setError(error);
      });
  };

  useEffect(() => {
    if (props.isOpen) {
      setSelectedLanguage(detectDefaultLanguage());
    }

    setLoading(false);
  }, [props.isOpen]);

  return (
    <SideBar
      title={t("languages.title")}
      {...props}
      footer={
        <div className="d-flex">
          <Button
            size="sm"
            className="px-5 py-2 ml-auto btn-accent"
            disabled={loading || selectedLanguage === detectDefaultLanguage()}
            onClick={() => onChangeLanguage()}
          >
            Save
          </Button>
        </div>
      }
    >
      <FormGroup>
        <hr style={{ marginLeft: "-40px", marginRight: "-40px" }} />
        <div className="d-flex items-center">
          <span className="main-text bold">{t("languages.french")}</span>

          <div
            onClick={() => {
              setSelectedLanguage("fr-CA");
            }}
            className="ml-auto"
          >
            <CustomInput onChange={() => {}} checked={selectedLanguage === "fr-CA"} type="radio" id="exampleCustomRadio" name="customRadio" />
          </div>
        </div>
        <hr style={{ marginLeft: "-40px", marginRight: "-40px" }} />
        <div className="d-flex items-center">
          <span className="main-text bold">{t("languages.english")}</span>
          <div onClick={() => setSelectedLanguage("en")} className="ml-auto">
            <CustomInput checked={selectedLanguage === "en"} onChange={() => {}} type="radio" id="exampleCustomRadio2" name="customRadio" />
          </div>
        </div>
        <hr style={{ marginLeft: "-40px", marginRight: "-40px" }} />
      </FormGroup>

      {loading && <Loader className="mt-5" title={t("common.loading")} />}
      {error && (
        <div className="error-alert mt-3 mx-auto" style={{ padding: "0.6rem", maxWidth: "100%" }}>
          {error}
        </div>
      )}
    </SideBar>
  );
};

export default LanguagesSidebar;
