import React, { useEffect, useState } from "react";
import SideBar from ".";
import axios from "axios";
import { Button } from "reactstrap";
import StripeCardElement from "../CardElement";
import { useTranslation } from "react-i18next";
import Loader from "../Loader";
import { parseError } from "../../helpers/common";
import Svg from "../Svg";
import authProvider from "../../helpers/authProvider";

const CheckoutPaymentSidebar = (props) => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [appointmentLoading, setAppointmentLoading] = useState(false);
  const [error, setError] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);

  useEffect(() => {
    if (props.isOpen) {
      setPaymentMethod(null);
      setError(null);
      setLoading(true);

      axios
        .get(`${process.env.REACT_APP_API_URL}/account/payment-method`)
        .then((res) => {
          setLoading(false);
          if (res.data) {
            setPaymentMethod(res.data);
          } else {
            setPaymentMethod(null);
          }
        })
        .catch((err) => {
          let error = parseError(err);
          setError(error);
          setLoading(false);
        });
    } else {
      setError(null);
      setLoading(false);
      setPaymentMethod(null);
      setAppointmentLoading(false);
    }
  }, [props.isOpen]);

  const onAddNewCard = (card) => {
    setPaymentMethod(card);
  };

  const onAppointmentPay = async () => {
    if (
      !props.people ||
      !props.address ||
      !props.appointmentType ||
      !props.cost ||
      (props.appointmentType === "scheduled" && !props.scheduledDate) ||
      props.people.length === 0
    ) {
      return;
    }

    setError(null);
    setAppointmentLoading(true);

    let peopleList = props.people;
    let uploadCompleted = false;

    try {
      for (const person of peopleList) {
        for (const pService of person.services) {
          if (!pService.requisitionFormFile && !pService.insuranceFormFile) {
            continue;
          }

          if (pService.requisitionFormFile) {
            if (typeof pService.requisitionFormFile === "string") {
              pService.requisitionFormFileId = pService.requisitionFormFile;
            } else {
              const file = pService.requisitionFormFile;
              let formData = new FormData();
              formData.append("file", file, file.name);

              let fileRes = await axios.post(
                `${process.env.REACT_APP_API_URL}/file/upload/requisitionform`,
                formData,
                {
                  headers: { "Content-Type": "multipart/form-data" },
                }
              );

              pService.requisitionFormFileId = fileRes.data;
            }
          }

          if (pService.insuranceFormFile) {
            if (typeof pService.insuranceFormFile === "string") {
              pService.insuranceFormFileId = pService.insuranceFormFile;
            } else {
              const file = pService.insuranceFormFile;
              let formData = new FormData();
              formData.append("file", file, file.name);

              let fileRes = await axios.post(
                `${process.env.REACT_APP_API_URL}/file/upload/insuranceform`,
                formData,
                {
                  headers: { "Content-Type": "multipart/form-data" },
                }
              );

              pService.insuranceFormFileId = fileRes.data;
            }
          }
        }

        if (person.healthCareCard && person.healthCareCard.uploadedFile) {
          const file = person.healthCareCard.uploadedFile;
          let formData = new FormData();
          formData.append("file", file, file.name);

          let fileRes = await axios.post(
            `${process.env.REACT_APP_API_URL}/file/upload/healthcard`,
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );

          person.healthCareCard.picture = {
            fileName: fileRes.data,
          };
        }
      }

      uploadCompleted = true;
    } catch (err) {
      let error = parseError(err);
      setError(error);
      setAppointmentLoading(false);
    }

    if (uploadCompleted) {
      try {
        var patients = peopleList.map((person) => {
          return {
            firstName: person.firstName,
            lastName: person.lastName,
            email: person.email,
            phone: person.phone,
            dateOfBirth: person.dateOfBirth,
            gender: person.gender,
            appointmentItems: person.services.map((pSvc) => {
              return {
                key: pSvc.key || null,
                serviceId: pSvc.id,
                questionnaire: (pSvc.questionnaire || []).map((q) => {
                  return {
                    id: q.id,
                    completed: q.completed,
                    optionIds: q.optionIds || [],
                    notes: q.notes || null,
                    version: q.version || "v2",
                  };
                }),
                requisitionFormFileId: pSvc.requisitionFormFileId,
                insuranceFormFileId: pSvc.insuranceFormFileId,
              };
            }),
            healthCareCard: person.healthCareCard,
            insurance: person.insurance,
            issuesAllergies: person.issuesAllergies,
          };
        });

        let address = props.address;
        let appData = {
          appointmentPatients: patients,
          address: {
            province: address.provinceCode || address.province,
            city: address.place || address.city,
            street: address.street,
            postalCode: address.postCode || address.postalCode,
            unitNumber: address.unitNumber || null,
          },
          latitude: address.latitude,
          longitude: address.longitude,
          discountCode: props.discountCode || null,
          notes: props.notes,
          type: props.appointmentType,
          startTime:
            props.appointmentType === "asap"
              ? null
              : {
                  year: props.scheduledDate.getFullYear(),
                  month: props.scheduledDate.getMonth() + 1,
                  day: props.scheduledDate.getDate(),
                  hour: props.scheduledDate.getHours(),
                  minute: props.scheduledDate.getMinutes(),
                },
        };

        let appRes =
          props.appointment != null
            ? await axios.put(
                `${process.env.REACT_APP_API_URL}/appointments/${props.appointment.id}`,
                appData
              )
            : await axios.post(
                `${process.env.REACT_APP_API_URL}/appointments`,
                Object.assign(appData, { profile: authProvider.getCurrentProfileType() })
              );

        let appId = appRes.data.id;

        if (props.onSuccess) {
          props.onSuccess(appId);
        }
      } catch (err) {
        let error = parseError(err);
        setError(error);
        setAppointmentLoading(false);
      }
    }
  };

  return (
    <SideBar
      title={props.paymentMode === "nocharge" ? t("appointmentDetails.title") : t("Payment.title")}
      {...props}
    >
      <div className="d-flex gap-24 flex-column">
        {props.paymentMode === "card" && (
          <React.Fragment>
            {loading && <Loader title={t("common.loading")} />}

            {paymentMethod && (
              <React.Fragment>
                <div className="d-flex items-center avvy-card-small gap-12 py-3">
                  <Svg name="credit_card" className="w-24 h-24 theme-dark-text" />
                  {t("paymentInformation.text")} {paymentMethod.last4}
                </div>

                <Button
                  disabled={
                    paymentMethod == null ||
                    appointmentLoading ||
                    !props.people ||
                    props.people.length === 0 ||
                    !props.address ||
                    !props.appointmentType ||
                    (props.appointmentType === "scheduled" && !props.scheduledDate)
                  }
                  className="w-100 btn-accent"
                  onClick={() => onAppointmentPay()}
                >
                  {t("Payment.btn")}
                </Button>

                <hr style={{ margin: 0 }} />
              </React.Fragment>
            )}

            <StripeCardElement
              toggle={() => {}}
              isVisible={true}
              onCardAdded={(card) => onAddNewCard(card)}
            />
          </React.Fragment>
        )}

        {props.paymentMode === "nocharge" && (
          <React.Fragment>
            <div className="main-text text-normal">
              {t("appointmentDetails.appointmentChangesConfirmation")}
            </div>
            <Button
              disabled={
                appointmentLoading ||
                !props.people ||
                props.people.length === 0 ||
                !props.address ||
                !props.appointmentType ||
                (props.appointmentType === "scheduled" && !props.scheduledDate)
              }
              className="w-100 btn-accent"
              onClick={() => onAppointmentPay()}
            >
              {t("appointmentDetails.editConfirmBtn")}
            </Button>
          </React.Fragment>
        )}

        {appointmentLoading && <Loader title={t("common.loading")} />}

        {error && (
          <div
            className="error-alert"
            style={{ margin: "12px auto", padding: "0.6rem", maxWidth: "500px" }}
          >
            {error}
          </div>
        )}
      </div>
    </SideBar>
  );
};

export default CheckoutPaymentSidebar;
