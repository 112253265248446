import React from "react";
import "./index.css";

const Loader = ({ className, ...props }) => {
  return (
    <div
      className={`avvy-loader d-flex flex-column items-center gap-6 ${className || ""}`}
      {...props}
    >
      <div className="heartbeat-loader">
        <svg
          width="80"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          viewBox="0 0 300 300"
        >
          <polyline
            className="loader-pulse loader-style"
            points="38,160.7 103.3,160.7 131.3,218 168.7,82 188.7,162 262,162 "
          />
          <polyline
            className="loader-pulse loader-opacity"
            points="38,160.7 103.3,160.7 131.3,218 168.7,82 188.7,162 262,162 "
          />
        </svg>
      </div>

      {props.title && (
        <div style={{ fontSize: "14px" }} className="theme-dark-text">
          {props.title}
        </div>
      )}
    </div>
  );
};

export default Loader;
