import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import moment from "moment";
import { Button, Container } from "reactstrap";
import PaymentSideBar from "../../components/SideBar/CheckoutPayment";
import AppointmentDetails from "../../components/AppointmentDetails";
import CheckoutDetails from "../../components/CheckoutDetails";
import CancelModal from "../../components/Modals/CancelModal";
import Svg from "../../components/Svg/index.js";
import Loader from "../../components/Loader";
import { useTranslation } from "react-i18next";
import { formatFullAddress, parseError } from "../../helpers/common";
import { toast } from "react-toastify";
import { DASHBOARD_URL, VIEW_APPOINTMENT_URL } from "../../urls";
import authProvider from "../../helpers/authProvider";

const EditAppointment = (props) => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [appointmentData, setAppointmentData] = useState(null);
  const [people, setPeople] = useState([]);
  const [address, setAddress] = useState(null);
  const [scheduledDate, setScheduledDate] = useState(null);
  const [discountCode, setDiscountCode] = useState("");
  const [notes, setNotes] = useState("");
  const [costData, setCostData] = useState(null);
  const [appointmentType, setAppointmentType] = useState("scheduled");
  const [paymentSideBarOpen, setPaymentSideBarOpen] = useState(false);
  const [checkoutEnabled, setCheckoutEnabled] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editEnabled, setEditEnabled] = useState(false);
  const [cancelEnabled, setCancelEnabled] = useState(false);
  const [paymentMode, setPaymentMode] = useState("card");
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [notesWords, setNotesWords] = useState(0);
  const [maxNotesWords] = useState(100);

  useEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });

    fetchAppointment();
  }, [props.match.params.id]);

  useEffect(() => {
    if (people && address && scheduledDate && costData && editMode && notesWords <= maxNotesWords) {
      setCheckoutEnabled(true);
    } else {
      setCheckoutEnabled(false);
    }
  }, [people, address, scheduledDate, costData, editMode, notesWords, maxNotesWords]);

  const fetchAppointment = () => {
    setEditMode(false);
    setCancelEnabled(false);
    setEditEnabled(false);
    setLoading(false);
    setError(null);
    setAppointmentData(null);
    setPeople([]);
    setScheduledDate(null);
    setDiscountCode("");
    setNotes("");
    setAddress(null);
    setCostData(null);
    setPaymentSideBarOpen(false);
    setCheckoutEnabled(false);
    setAppointmentType("scheduled");
    setIsCancelModalOpen(false);

    const appId = props.match.params.id;
    if (!appId) {
      return;
    }

    setLoading(true);

    axios
      .get(`${process.env.REACT_APP_API_URL}/appointments/${appId}`)
      .then((res) => {
        setLoading(false);
        const app = res.data;
        if (app.status === "technician_in_transit" || app.status === "technician_at_location") {
          props.history.push(VIEW_APPOINTMENT_URL.replace(":id", app.id));
          return;
        }

        initializeAppointment(res.data);
      })
      .catch((err) => {
        let error = parseError(err);
        setError(error);
        setLoading(false);
      });
  };

  const initializeAppointment = (appointment) => {
    setAppointmentType(appointment.type);
    setCostData(appointment.cost);
    setDiscountCode(appointment.discountCodeUsed);
    setNotes(appointment.patientNotes || "");

    const peopleList = appointment.appointmentPatients;

    peopleList.forEach((person) => {
      const services = person.appointmentItems.map((ai) => {
        const service = Object.assign({ key: ai.key }, ai.service);

        if (service.requisitionFormId) {
          service.requisitionFormFile = service.requisitionFormId;
        }

        if (service.insuranceFormId) {
          service.insuranceFormFile = service.insuranceFormId;
        }

        return service;
      });
      person.services = services;
    });

    setPeople(peopleList);
    setAddress(
      Object.assign(
        Object.assign(
          {
            fullAddress: formatFullAddress(appointment.address),
            provinceCode: appointment.address.province,
          },
          appointment.address
        ),
        appointment.location
      )
    );

    if (appointment.type === "scheduled") {
      const appTime = appointment.startTime;
      const scheduledDate = moment(
        `${appTime.day}/${appTime.month}/${appTime.year} ${appTime.hour}:${appTime.minute}:${appTime.second}`,
        "DD/MM/YYYY HH:mm:ss"
      );
      setScheduledDate(scheduledDate.toDate());
    } else {
      setScheduledDate(null);
    }

    setEditEnabled(appointment.status === "technician_assigned");
    setCancelEnabled(
      appointment.type === "scheduled" && appointment.status === "technician_assigned"
    );
    setAppointmentData(appointment);
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Avvy - {t("appointmentDetails.title")}</title>
      </Helmet>

      <main style={{ minHeight: "90vh" }} className="d-flex flex-column appointment-page">
        {loading && <Loader className="mt-8" title={t("common.loading")} />}
        {error && (
          <div className="error-alert mt-3 mx-auto" style={{ padding: "0.6rem", maxWidth: "100%" }}>
            {error}
          </div>
        )}

        {appointmentData && (
          <Container>
            <section className="flex-column d-flex gap-16">
              <span
                className="mr-auto cursor-pointer"
                onClick={() => props.history.goBack()}
                style={{}}
              >
                <Svg name="arrow-left" className="page-back-arrow theme-dark-color" />
              </span>

              <div
                className="flex-row d-flex page-header-container"
                style={{ alignItems: "flex-start" }}
              >
                <h2 className="page-header mr-auto">
                  {t("appointmentDetails.title")}
                  <span
                    className="text-small text-normal sub-text regular"
                    style={{ marginLeft: "10px" }}
                  >
                    {appointmentData.profile === "personal"
                      ? t("corporate.personalAccount")
                      : t("corporate.corporateAccount")}
                  </span>
                </h2>

                <div className="d-flex ml-auto gap-6">
                  {cancelEnabled && !editMode && (
                    <Button color="secondary" outline onClick={() => setIsCancelModalOpen(true)}>
                      {t("cancelAppointment.title")}
                    </Button>
                  )}

                  {editMode && editEnabled && (
                    <Button
                      color="secondary"
                      outline
                      onClick={() => {
                        fetchAppointment();
                      }}
                    >
                      {t("appointmentDetails.btnCancel")}
                    </Button>
                  )}

                  {!editMode && editEnabled && (
                    <Button
                      className="btn-accent"
                      onClick={() => {
                        setEditMode(true);
                      }}
                    >
                      {t("appointmentDetails.btnEdit")}
                    </Button>
                  )}

                  {editMode && (
                    <Button
                      className="btn-accent px-5"
                      disabled={!checkoutEnabled}
                      onClick={() => {
                        if (costData.total > appointmentData.cost.total) {
                          setPaymentMode("card");
                          setPaymentSideBarOpen(true);
                        } else {
                          setPaymentMode("nocharge");
                          setPaymentSideBarOpen(true);
                        }
                      }}
                    >
                      {t("newBooking.btnSave")}
                    </Button>
                  )}
                </div>
              </div>
            </section>

            <section className="d-flex booking-section" style={{ gap: "24px" }}>
              <AppointmentDetails
                scheduleChanged={(date, type) => {
                  setScheduledDate(date);
                  setAppointmentType(type);
                }}
                scheduledDate={scheduledDate}
                appointmentType={appointmentType}
                updateLocation={(location) => setAddress(location)}
                address={address}
                editMode={editMode}
                blockSchedule={true}
                people={people}
                updatePeopleArray={(list) => setPeople(list)}
                updateDiscountCode={(value) => setDiscountCode(value)}
                updateNotes={(value, words) => {
                  setNotes(value || "");
                  setNotesWords(words);
                }}
                appointment={appointmentData}
                notes={notes}
                maxNotesWords={maxNotesWords}
                discountCode={discountCode}
                {...props}
              />

              <CheckoutDetails
                appointment={appointmentData}
                discountCode={discountCode}
                address={address}
                scheduledDate={scheduledDate}
                appointmentType={appointmentType}
                people={people}
                costData={costData}
                editMode={true}
                showPaidAmount={true}
                costUpdated={(cost) => {
                  setCostData(cost);
                }}
                {...props}
              />
            </section>

            <PaymentSideBar
              onSuccess={(appId) => {
                toast.dark(t("newBooking.updatedMessage"), {
                  position: "top-right",
                  autoClose: 4000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                fetchAppointment();
              }}
              paymentMode={paymentMode}
              appointment={appointmentData}
              isOpen={paymentSideBarOpen}
              toggle={() => setPaymentSideBarOpen(false)}
              address={address}
              scheduledDate={scheduledDate}
              appointmentType={appointmentType}
              notes={notes}
              discountCode={discountCode}
              people={people}
              cost={costData}
              {...props}
            />

            {cancelEnabled && (
              <CancelModal
                appointment={appointmentData}
                isOpen={isCancelModalOpen}
                toggle={() => setIsCancelModalOpen(false)}
                onCancelled={() => {
                  toast.dark(t("appointmentCancelled.text"), {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });

                  props.history.push(DASHBOARD_URL);
                }}
              />
            )}
          </Container>
        )}
      </main>
    </React.Fragment>
  );
};
export default EditAppointment;
