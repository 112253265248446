import React, { useState, useEffect } from "react";
import axios from "axios";
import Svg from "../Svg";
import { Button, Card, CardBody, FormGroup, Input, InputGroup, Modal } from "reactstrap";
import ModalHeader from "reactstrap/lib/ModalHeader";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";
import { parseError } from "../../helpers/common";

const VerifyEmailModal = (props) => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [code, setCode] = useState("");

  useEffect(() => {
    setCode("");
    setError(null);
    setLoading(false);
  }, [props.isOpen]);

  const onVerifyCode = () => {
    setLoading(true);
    setError(null);

    const verificationData = {
      email: props.email,
      code: code,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/account/ConfirmEmail`, verificationData)
      .then((res) => {
        setLoading(false);
        props.onConfirmed();
        props.toggle();
        setError(null);
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        setError(error);
      });
  };

  return (
    <React.Fragment>
      <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={props.isOpen}
        toggle={() => props.toggle()}
      >
        <ModalHeader
          close={
            <button className="close" onClick={() => props.toggle()}>
              <Svg name="icon_cancel_grey" className="w-24 h-24 sub-text" />
            </button>
          }
        >
          <div className="text-muted text-center mt-0 mx-auto">
            <h6 className="mb-0">{t("emailVerification.title")}</h6>
          </div>
        </ModalHeader>
        <div className="modal-body p-0">
          <Card className=" border-0">
            <CardBody className="px-lg-4 py-lg-4">
              <div className="d-flex flex-column gap-12">
                <div className="main-text text-normal">{t("emailVerification.subText")}</div>

                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <Input
                      required
                      placeholder={t("accountVerification.placeholder")}
                      type="text"
                      value={code}
                      onChange={(e) => {
                        setCode(e.target.value);
                      }}
                    />
                  </InputGroup>
                </FormGroup>

                <Button
                  className="btn-accent"
                  type="button"
                  disabled={!code || loading}
                  onClick={() => onVerifyCode()}
                >
                  {t("emailVerification.ButtonSubmit")}
                </Button>

                {loading && <Loader title={t("common.loading")} />}

                {error && (
                  <div
                    className="error-alert mt-3 mx-auto"
                    style={{ padding: "0.6rem", maxWidth: "100%" }}
                  >
                    {error}
                  </div>
                )}
              </div>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default VerifyEmailModal;
