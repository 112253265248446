import React from "react";

const Checkbox = ({ id, checked, handleChange, label, labelHtml, className }) => (
  <div className={`d-flex items-start gap-6 ${className || ""}`}>
    <input
      type="checkbox"
      className="flex-shrink-0"
      id={`checkbox-${id}`}
      checked={checked}
      onChange={(e) => {
        handleChange(id, e.target.checked);
      }}
    />
    {labelHtml ? (
      <label
        onClick={(e) => {
          handleChange(id, !checked);
        }}
        style={{ marginTop: "-2px" }}
        htmlFor={`checkbox-${id}`}
        dangerouslySetInnerHTML={{ __html: labelHtml || "" }}
      ></label>
    ) : (
      <label
        onClick={(e) => {
          handleChange(id, !checked);
        }}
        style={{ marginTop: "-2px" }}
        htmlFor={`checkbox-${id}`}
      >
        {label || ""}
      </label>
    )}
  </div>
);

export default Checkbox;
