import * as React from "react";
import { Redirect, Route } from "react-router-dom";
import authProvider from "../../helpers/authProvider";

export default function PrivateRoute({ path, element, ...rest }) {
  if (!authProvider.isAuthenticated()) {
    return <Redirect to="/auth/login" />;
  }

  return <Route path={path} element={element} {...rest} />;
}
