import React, { useEffect, useState } from "react";
import Svg from "../Svg";

const MaskShapeImage = (props) => {
  const [image, setImage] = useState();

  useEffect(() => {
    setImage(props.image);
  }, [props.image]);
  return (
    <React.Fragment>
      <svg
        className={`${props.className || ""} theme-dark-text`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1160 870"
      >
        <defs>
          <pattern
            id={`img${props.className}-${props.index}`}
            patternUnits="userSpaceOnUse"
            width="100%"
            height="100%"
            x="0"
            y="0"
          >
            <rect fill="#f4f4f4" x="0" y="0" height="100%" />
            {!image && (
              <Svg name="avvy-shape" className="accent-text opacity-5" x="0" y="0" height="100%" />
            )}
            {image && <image href={image} x="0" y="0" height="100%" />}
          </pattern>
        </defs>
        <path
          fill={`url(#img${props.className}-${props.index})`}
          d="M545.16,480.83l-.36.13a119.39,119.39,0,0,1-152.92-71.6A118.07,118.07,0,0,1,385,376.08a116.27,116.27,0,0,1,2.62-32.64,30.52,30.52,0,0,1,.76-3.9v0l9.93-56.34L448.14,0H57.87L13.36,252.09a.49.49,0,0,1,0,.39.48.48,0,0,0,0,.37A505,505,0,0,0,180.15,754.44c11.65,9.8,23.86,19.16,36.28,27.83,12.26,8.55,25,16.64,38,24s26,14.13,39.38,20.27,26.85,11.64,40.55,16.54,27.54,9.16,41.49,12.82,28,6.7,42.17,9.13,28.39,4.25,42.61,5.46,28.57,1.81,42.8,1.81a508.42,508.42,0,0,0,87.78-7.66c14.45-2.54,29-5.76,43.12-9.54s28.44-8.29,42.26-13.32l.33-.11L1161.3,665.39V256.57Z"
        />
      </svg>
    </React.Fragment>
  );
};

export default MaskShapeImage;
