import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Row, Col, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../Loader";
import authProvider from "../../helpers/authProvider";
import { parseError } from "../../helpers/common";

const DeleteAccountModal = (props) => {
  const { t } = useTranslation("common");
  const [userData] = useState(authProvider.getUser());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(false);
    setError(null);
  }, [props.modalOpen]);

  const onAccountDelete = () => {
    setLoading(true);
    setError(null);

    axios
      .delete(`${process.env.REACT_APP_API_URL}/account/${userData.id}`)
      .then((res) => {
        toast.success(t("accountDeletion.deleteConfirmation"), {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        if (props.onDeleted) {
          props.onDeleted();
        }
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        setError(error);
      });
  };

  return (
    <Modal
      className="modal-dialog-centered confirm-modal"
      style={{ minWidth: 600 }}
      isOpen={props.modalOpen}
      toggle={() => props.toggle()}
    >
      <ModalBody>
        <Row>
          <Col lg={12}>
            <h2 style={{ color: "#313131", fontSize: 19, fontWeight: 700 }}>
              {t("accountDeletion.title")}
            </h2>
          </Col>
          <Col lg={12}>
            <p>{t("accountDeletion.message")}</p>
          </Col>
        </Row>
        <Row>
          <Col lg={12} style={{ marginBottom: "24px" }}>
            {loading && <Loader title={t("common.loading")} />}
            {error && (
              <div
                className="error-alert mt-3 mx-auto"
                style={{ padding: "0.6rem", maxWidth: "100%" }}
              >
                {error}
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col style={{ textAlign: "end" }} lg={12}>
            <Button outline color="secondary" disabled={loading} onClick={() => props.toggle()}>
              {t("confirmDeletion.btnCancel")}
            </Button>
            <Button color="danger" onClick={onAccountDelete} disabled={loading}>
              {t("accountDeletion.confirmText")}
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default DeleteAccountModal;
