import React, { useEffect, useState } from "react";
import { FormGroup, Button, CustomInput } from "reactstrap";
import { formatPhoneNumber } from "../../assets/js/formatePhoneNumber";
import { useTranslation } from "react-i18next";
import Svg from "../Svg";

const FamilyMemberCard = (props) => {
  const { t } = useTranslation("common");
  const [member, setMember] = useState({
    firstName: "",
    lastName: "",
    checked: false,
    email: "",
    phone: "",
  });

  useEffect(() => {
    if (props.member) {
      setMember(props.member);
    }
  }, [props.member]);

  return (
    <FormGroup className="avvy-card d-flex flex-column" style={{ gap: "16px" }} check>
      <div
        className="position-relative"
        onClick={() => {
          if (!props.edit && props.onChecked) {
            props.onChecked(member);
          }
        }}
      >
        <div className="position-absolute" style={{ right: 0, top: 0 }}>
          {props.edit ? (
            <Button
              className="ml-auto"
              onClick={() => {
                if (props.onEdit) {
                  props.onEdit(member);
                }
              }}
              color="link"
              style={{ padding: 0 }}
            >
              <Svg name="edit" className="accent-text w-24 h-24" alt="Edit Pencil" />
            </Button>
          ) : (
            <CustomInput
              type="checkbox"
              id={member.id || props.member.id}
              checked={member.checked}
              onChange={() => {}}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();

                if (props.onEdit) {
                  props.onEdit(member);
                }
              }}
            />
          )}
        </div>

        <div className="d-flex flex-column gap-16">
          <div className="d-flex flex-row" style={{ alignItems: "flex-start" }}>
            <div className="d-flex flex-row mr-auto" style={{ alignItems: "center", gap: "12px" }}>
              <Svg className="theme-dark-text w-24 h-24" name="user-icon" alt="Person Avatar" />
              <span className="avvy-card-header">
                {member.firstName} {member.lastName}
              </span>
            </div>
          </div>

          <div className="d-flex flex-column" style={{ gap: "6px" }}>
            <div className="app-details-row">
              <span className="app-details-label">{t("newBooking.email")}</span>
              <span className="app-details-value">{member.email}</span>
            </div>
            <div className="app-details-row">
              <span className="app-details-label">{t("newBooking.phone")}</span>
              <span className="app-details-value">{formatPhoneNumber(member.phone)}</span>
            </div>
          </div>
        </div>
      </div>
    </FormGroup>
  );
};
export default FamilyMemberCard;
