import React, { useEffect, useState } from "react";
import SideBar from ".";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { parseError } from "../../helpers/common";

const TOSSidebar = (props) => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [aboutContent, setAboutContent] = useState("");

  useEffect(() => {
    if (props.isOpen) {
      getContent();
    } else {
      setAboutContent("");
      setLoading(false);
      setError(null);
    }
  }, [props.isOpen]);

  const getContent = () => {
    setLoading(true);
    setError(null);
    setAboutContent(null);

    axios
      .get(`${process.env.REACT_APP_API_URL}/platform/abouttheapp`)
      .then((res) => {
        setLoading(false);
        setAboutContent(res.data || "");
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        setError(error);
      });
  };

  return (
    <SideBar {...props} title={t("about.title")}>
      <div className="d-flex flex-column">
        {loading && <Loader className="mt-5" title={t("common.loading")} />}
        {error && (
          <div className="error-alert mt-5 mx-auto" style={{ padding: "0.6rem", maxWidth: "100%" }}>
            {error}
          </div>
        )}

        <div dangerouslySetInnerHTML={{ __html: aboutContent }} className="main-text"></div>
      </div>
    </SideBar>
  );
};

export default TOSSidebar;
