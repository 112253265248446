import React from "react";
import { useTranslation } from "react-i18next";
import Svg from "../../components/Svg";

const CorporateProfileView = ({ profile }) => {
  const { t, i18n } = useTranslation("common");

  return (
    <section className="corporate-profile-details">
      <div className="corporate-block">
        <div className="corporate-avatar">
          <img src={profile.picture.url} alt={profile.name} />
        </div>
        <p>{profile.name}</p>
      </div>

      <div className="corporate-block">
        <Svg name="arrows-lr" className="accent-text" width="100%" height="100%" />
      </div>

      <div className="corporate-block">
        <div className="corporate-avatar">
          <Svg name="avvy-shape" className="accent-text" width="100%" height="100%" />
        </div>
        <p>Avvy</p>
      </div>
    </section>
  );
};
export default CorporateProfileView;
