import React, { useEffect, useState, useRef } from "react";
import SideBar from ".";
import axios from "axios";
import { Button } from "reactstrap";
import ConfirmModal from "../Modals/ConfirmModal";
import Svg from "../Svg";
import Loader from "../Loader";
import StripeCardElement from "../../components/CardElement/index.js";
import { useTranslation } from "react-i18next";
import { parseError } from "../../helpers/common";

const PaymentInfoSidebar = (props) => {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [addCardOpen, setAddCardOpen] = useState(false);
  const stripeRef = useRef(null);

  useEffect(() => {
    if (props.isOpen) {
      getUserPaymentMethod();
    } else {
      setPaymentMethod(null);
      setError(null);
      setLoading(false);
      setAddCardOpen(false);
    }
  }, [props.isOpen]);

  const onDeleteCard = () => {
    if (!loading && paymentMethod) {
      setConfirmModal(!confirmModal);
    }
  };

  const getUserPaymentMethod = () => {
    setLoading(true);
    setError(null);
    setPaymentMethod(null);

    axios
      .get(`${process.env.REACT_APP_API_URL}/account/payment-method`)
      .then((res) => {
        setLoading(false);
        if (res.data) {
          setPaymentMethod(res.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        setError(error);
      });
  };

  const deleteCard = () => {
    setLoading(true);
    setError(null);
    setConfirmModal(false);

    axios
      .delete(`${process.env.REACT_APP_API_URL}/account/payment-method`)
      .then((res) => {
        setLoading(false);
        setPaymentMethod(null);
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        setError(error);
      });
  };

  return (
    <React.Fragment>
      <SideBar title={t("paymentInfo.title")} {...props}>
        <div className="d-flex flex-column gap-16">
          {paymentMethod && (
            <div className="avvy-card d-flex items-center">
              <Svg className="theme-dark-text w-24 h-24 mr-2" name="credit_card" />
              <span className="theme-dark-text">
                {t("paymentInformation.text")} {paymentMethod.last4}
              </span>
              <Svg
                onClick={() => onDeleteCard()}
                className="error-text w-24 h-24 ml-auto cursor-pointer"
                name="icon_cancel"
              />
            </div>
          )}

          {!paymentMethod && !loading && !error && (
            <div className="d-flex flex-column gap-16">
              <Svg
                style={{
                  width: "140px",
                  background: "var(--theme-background-color)",
                  padding: 24,
                  borderRadius: "50%",
                  transform: "rotate(-15deg)",
                  marginBottom: 20,
                }}
                className="theme-dark-text content-center"
                name="credit_card"
              />

              <p className="text-center sub-text">{t("paymentInfo.card")}</p>

              <Button onClick={() => setAddCardOpen(true)} outline className="content-center px-6">
                {t("paymentInfo.btn")}
              </Button>
            </div>
          )}

          {loading && <Loader className="mt-4" title={t("common.loading")} />}
          {error && (
            <div
              className="error-alert mt-3 mx-auto"
              style={{ padding: "0.6rem", maxWidth: "100%" }}
            >
              {error}
            </div>
          )}
        </div>
      </SideBar>

      <SideBar
        sidebarGroup="profile-payments"
        title={t("paymentInfo.btn")}
        {...props}
        isOpen={addCardOpen}
        toggle={() => {
          setAddCardOpen(false);
        }}
      >
        <StripeCardElement
          isVisible={addCardOpen}
          ref={stripeRef}
          toggle={() => {
            setAddCardOpen(false);
            getUserPaymentMethod();
          }}
        />
      </SideBar>

      <ConfirmModal
        modalOpen={confirmModal}
        toggle={() => {
          setConfirmModal(!confirmModal);
        }}
        callBack={() => {
          deleteCard();
        }}
        confirmButton={t("confirmDeleteCardModal.confirmButton")}
        header={t("confirmDeleteCardModal.header")}
        body={t("confirmDeleteCardModal.body")}
      />
    </React.Fragment>
  );
};

export default PaymentInfoSidebar;
