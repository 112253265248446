import React, { useEffect, useState } from "react";
import axios from "axios";
import SideBar from ".";
import MaskShapeImage from "../MaskShapeImage";
import Loader from "../Loader";
import Svg from "../Svg";
import { Input } from "reactstrap";
import { parseError, formatMoney } from "../../helpers/common";
import { useTranslation } from "react-i18next";
import authProvider from "../../helpers/authProvider";
import ServiceCard from "../ServiceCard";

const ServicesSidebar = ({ selectedServices, ...props }) => {
  const { t, i18n } = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [services, setServices] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (props.isOpen && services.length === 0) {
      loadServices();
    } else if (!props.isOpen) {
      setServices([]);
      setError(null);
      setLoading(false);
    }

    window.addEventListener("profileTypeChanged", onProfileTypeChanged, true);

    return function cleanup() {
      window.removeEventListener("profileTypeChanged", onProfileTypeChanged, true);
    };
  }, [props.isOpen]);

  const onProfileTypeChanged = () => {
    if (props.isOpen) {
      loadServices();
    }
  };

  const loadServices = () => {
    setLoading(true);
    setError(null);

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/services?profile=${
          props.profileType ? props.profileType : authProvider.getCurrentProfileType()
        }`
      )
      .then((res) => {
        setLoading(false);
        setServices(res.data);
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        setError(error);
      });
  };

  const filterSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  return (
    <SideBar
      title={t("newBooking.services")}
      back={true}
      {...props}
      style={{ backgroundColor: "#F1F2F2" }}
    >
      <div style={{ position: "relative", marginBottom: 20 }}>
        <Input style={{ paddingLeft: 50 }} onChange={(e) => filterSearch(e)} />
        <Svg
          style={{ position: "absolute", top: 12, left: 12, width: "20px", height: "20px" }}
          name="icon_search"
          className="theme-text"
        />
      </div>

      <span className="sub-text text-normal mb-3" style={{ fontSize: "13px", marginTop: "-12px" }}>
        {t("services.dynamicPriceDisclaimer")}
      </span>

      <div className="d-flex flex-column gap-12" style={{ paddingBottom: "60px" }}>
        {loading && <Loader title={t("common.loading")} />}

        {error && (
          <div
            className="error-alert"
            style={{ margin: "12px auto", padding: "0.6rem", maxWidth: "500px" }}
          >
            {error}
          </div>
        )}

        {!loading && !error && services.length === 0 && (
          <div
            className="mx-auto my-6"
            style={{
              padding: "0.6rem",
              maxWidth: "100%",
              fontSize: "14px",
              color: "var(--sub-text-color)",
            }}
          >
            {t("dashboard.noServicesAvailable")}
          </div>
        )}

        <div className="d-flex flex-column gap-16 align-items-stretch">
          {services
            .filter((s) => !(selectedServices || []).find((fs) => fs.id === s.id))
            .filter((s) => !searchQuery || s.title.toLowerCase().indexOf(searchQuery) !== -1)
            .map((service, index) => {
              return (
                <ServiceCard
                  service={service}
                  key={service.id}
                  onClick={() => {
                    props.onServiceSelected(service);
                  }}
                />
              );
            })}
        </div>
      </div>
    </SideBar>
  );
};

export default ServicesSidebar;
