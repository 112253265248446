import React, { useState, useEffect } from "react";
import { Button, Input, FormGroup, Col, Row } from "reactstrap";
import SideBar from ".";
import Loader from "../Loader";
import Rating from "react-rating";
import { useTranslation } from "react-i18next";
import Label from "reactstrap/lib/Label";
import { parseError } from "../../helpers/common";
import axios from "axios";

const RatingSideBar = (props) => {
  const { t } = useTranslation("common");
  const [ratingValue, setRatingValue] = useState(0);
  const [notes, setNotes] = useState("");
  const [percentTip, setPercentTip] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.isOpen && props.appointment) {
    } else {
      setNotes("");
      setPercentTip(0);
      setRatingValue(0);
      setLoading(false);
      setError(null);
    }
  }, [props.isOpen, props.appointment]);

  const submitRating = () => {
    setLoading(true);
    setError(null);

    axios
      .put(`${process.env.REACT_APP_API_URL}/appointments/${props.appointment.id}/rate`, {
        note: notes,
        rating: ratingValue,
        tipAmount: null,
        tipPercentage:
          percentTip && percentTip >= 0 && percentTip <= 100 ? parseFloat(percentTip) : null,
      })
      .then(() => {
        setLoading(false);
        props.onRated(ratingValue);
        props.toggle();
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        setError(error);
      });
  };

  return (
    <SideBar title={t("rateService.title")} {...props}>
      <div className="d-flex gap-16 flex-column">
        <div
          className="p-3 d-flex flex-column gap-6"
          style={{ border: "2px solid var(--theme-dark-color)", borderRadius: "6px" }}
        >
          <Rating
            className="mx-auto mb-4 d-flex justify-content-around w-full"
            initialRating={ratingValue}
            emptySymbol="fa fa-star-o fa-2x theme-dark-text"
            fullSymbol="fa fa-star fa-2x theme-dark-text"
            onChange={(value) => {
              setRatingValue(value);
            }}
          />
          <div className="text-center sub-text text-normal">{t("rateService.text")}</div>
          <Input
            value={notes}
            type="textarea"
            name="text"
            id="exampleText"
            placeholder={t("rateService.placeholder")}
            onChange={(e) => setNotes(e.target.value)}
            rows={5}
            maxLength={500}
            style={{ border: "2px solid var(--theme-dark-color)" }}
          />
        </div>

        <div
          className="p-3 d-flex flex-column gap-16"
          style={{ border: "2px solid var(--theme-dark-color)", borderRadius: "6px" }}
        >
          <FormGroup>
            <Label className="theme-dark-text bold text-normal">{t("rateService.tip")}</Label>
            <Row style={{ color: "#989CA3" }}>
              <Col>
                <Button
                  className={percentTip === 5 ? "button-selected bold" : "bold"}
                  style={{ width: "100%", border: "2px solid var(--theme-dark-color)", padding: 5 }}
                  color="link"
                  onClick={() => {
                    if (percentTip === 5) {
                      setPercentTip(0);
                    } else {
                      setPercentTip(5);
                    }
                  }}
                >
                  5%
                </Button>
              </Col>
              <Col>
                <Button
                  className={percentTip === 10 ? "button-selected bold" : "bold"}
                  style={{ width: "100%", border: "2px solid var(--theme-dark-color)", padding: 5 }}
                  color="link"
                  onClick={() => {
                    if (percentTip === 10) {
                      setPercentTip(0);
                    } else {
                      setPercentTip(10);
                    }
                  }}
                >
                  10%
                </Button>
              </Col>
              <Col>
                <Button
                  className={percentTip === 15 ? "button-selected bold" : "bold"}
                  style={{ width: "100%", border: "2px solid var(--theme-dark-color)", padding: 5 }}
                  color="link"
                  onClick={() => {
                    if (percentTip === 15) {
                      setPercentTip(0);
                    } else {
                      setPercentTip(15);
                    }
                  }}
                >
                  15%
                </Button>
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Label className="theme-dark-text bold text-normal">{t("rateService.other")}</Label>
            <div style={{ position: "relative" }}>
              <Input
                type="number"
                min={0}
                max={100}
                className="tip-input"
                placeholder={t("rateService.other")}
                onChange={(e) => setPercentTip(parseFloat(e.target.value))}
              />
              <span
                style={{
                  position: "absolute",
                  top: 8,
                  right: 13,
                  fontSize: 19,
                  background: "#fff",
                  color: "#6BD8E7",
                }}
              >
                %
              </span>
            </div>
          </FormGroup>
        </div>

        <Button
          onClick={() => submitRating()}
          size="md"
          className="w-full btn-accent"
          disabled={loading || !ratingValue || !notes || percentTip < 0 || percentTip > 100}
        >
          {t("rateService.btn")}
        </Button>

        {loading && <Loader className="mt-3" title={t("common.loading")} />}

        {error && (
          <div className="error-alert mt-3 mx-auto" style={{ padding: "0.6rem", maxWidth: "100%" }}>
            {error}
          </div>
        )}
      </div>
    </SideBar>
  );
};

export default RatingSideBar;
