import authProvider from "./authProvider";

export const timeFormat = "h:mm A";
export const dateFormat = "MMM D, YYYY";
export const dateTimeFormat = "MMM D, YYYY h:mm A";

export function detectDefaultLanguage() {
  const userData = authProvider.getUser();
  if (userData) {
    return userData.language;
  }

  const lang = localStorage.getItem("language") || navigator.language;

  if (lang.startsWith("fr-") || lang === "fr") {
    return "fr-CA";
  }

  return "en";
}

export function formatMoney(amount, lang) {
  return new Intl.NumberFormat(lang || detectDefaultLanguage(), {
    style: "currency",
    currency: "CAD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    signDisplay: "never",
    currencyDisplay: "symbol",
  })
    .format(amount && !Number.isNaN(amount) && (amount > 0 || amount < 0) ? amount / 100 : 0)
    .replace("CA", "");
}

export function changeLanguage(i18n, t, language) {
  localStorage.setItem("language", language);
  i18n.changeLanguage(language);
  window.translations = t;
}

export function parseError(err) {
  console.error(err);

  var hasResponse = err && err.response && err.response.data;
  if (hasResponse) {
    var msg = err.response.data.message;
    var errorList = err.response.data.errorList;
    if (msg) {
      if (errorList) {
        return `${msg}\n${errorList.join("\n")}`;
      }
      return msg;
    } else if (err.response.status === 429) {
      return window.translations("common.rateLimitError");
    } else if (err.response.data.errors) {
      let errors = err.response.data.errors;
      let errorMsg = "";
      Object.keys(errors).forEach((e) => (errorMsg += `${errors[e]}`));

      return errorMsg || window.translations("common.generalError");
    } else {
      return window.translations("common.generalError");
    }
  } else if (err.request) {
    return window.translations("common.networkError");
  }

  return window.translations("common.generalError");
}

export function formatFullAddress(address) {
  if (!address) {
    return "";
  }

  return `${address.unitNumber ? `${address.unitNumber}, ` : ""}${
    address.street ? `${address.street}, ` : ""
  }${address.city}, ${address.province} ${address.postCode || address.postalCode}`;
}

export function formatStreetAddress(address) {
  if (!address) {
    return "";
  }

  return `${address.street ? `${address.street}, ` : ""}${address.city}, ${address.province} ${
    address.postCode || address.postalCode
  }`;
}

export function formatDuration(durationInMinutes) {
  const formatter = new Intl.RelativeTimeFormat(detectDefaultLanguage(), {
    numeric: "auto",
  });

  const DIVISIONS = [
    { amount: 60, name: "seconds" },
    { amount: 60, name: "minutes" },
    { amount: 24, name: "hours" },
    { amount: 7, name: "days" },
    { amount: 4.34524, name: "weeks" },
    { amount: 12, name: "months" },
    { amount: Number.POSITIVE_INFINITY, name: "years" },
  ];

  let duration = parseInt(durationInMinutes) * 60;

  for (let i = 0; i <= DIVISIONS.length; i++) {
    const division = DIVISIONS[i];
    if (Math.abs(duration) < division.amount) {
      return formatter.format(Math.round(duration), division.name);
    }
    duration /= division.amount;
  }
}
