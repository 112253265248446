import React from "react";
import { Helmet } from "react-helmet";
import Index from "../Index";
import { Button } from "reactstrap";
import { useHistory, Redirect } from "react-router-dom";
import { DASHBOARD_URL, LANGUAGE } from "../../urls";
import { useTranslation } from "react-i18next";
import SectionImage from "../../assets/img/backgrounds/getting-started.jpg";
import authProvider from "../../helpers/authProvider";

const GetStarted = (props) => {
  const { t } = useTranslation("common");
  const history = useHistory();

  if (authProvider.isAuthenticated()) {
    return <Redirect to={DASHBOARD_URL} />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Avvy - {t("landingPage.button")}</title>
      </Helmet>

      <Index image={SectionImage} {...props}>
        <h1
          className="px-5 text-center position-relative stylized-header"
          dangerouslySetInnerHTML={{ __html: t("landingPage.banner") }}
        ></h1>
        <Button className="btn-accent" onClick={() => history.push(LANGUAGE)}>
          {t("landingPage.button")}
        </Button>
      </Index>
    </React.Fragment>
  );
};

export default GetStarted;
