import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Button, Container } from "reactstrap";
import { useHistory } from "react-router-dom";
import EditAddFamilyMemberView from "../../components/SideBar/EditAddFamilyMember";
import MembersCard from "../../components/MembersCard";
import Loader from "../../components/Loader";
import { CREATE_APPOINTMENT_URL } from "../../urls";
import { useTranslation } from "react-i18next";
import { formatFullAddress, parseError } from "../../helpers/common";
import authProvider from "../../helpers/authProvider";
import Svg from "../../components/Svg";

const SelectMember = (props) => {
  const { t } = useTranslation("common");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showMemberSidebar, setShowMemberSidebar] = useState(false);
  const [members, setMembers] = useState([]);
  const [nextPageToken, setNextPageToken] = useState(null);

  useEffect(() => {
    if (members.length === 0) {
      getMembers();
    }
  }, []);

  const onMemberSelected = (memberId) => {
    let memberArray = [...members];
    const memberIndex = memberArray.findIndex((m) => m.id === memberId);
    memberArray[memberIndex].checked = !memberArray[memberIndex].checked;
    setMembers(memberArray);
  };

  const onMemberCreated = (person) => {
    person.checked = false;
    setMembers((members) => [...members, person]);
  };

  const getMembers = (pageToken = null) => {
    setLoading(true);
    setError(null);

    if (!pageToken) {
      setMembers([]);
      setNextPageToken(null);
    }

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/account/familymembers?pageSize=15&paginationToken=${
          pageToken || ""
        }`
      )
      .then((res) => {
        setLoading(false);
        const list = res.data.items;
        list.forEach((s) => {
          s.checked = false;
        });
        setMembers((prev) => prev.concat(list));
        setNextPageToken(res.data.nextPageToken);
      })
      .catch((err) => {
        setLoading(false);
        let error = parseError(err);
        setError(error);
      });
  };

  const goToAppointmentBooking = () => {
    const currentUser = authProvider.getUser();
    const selectedMembers = members.filter((s) => s.checked === true);
    selectedMembers.forEach((m) => {
      m.isFamilyMember = true;
    });

    const preFilledAddress = {
      latitude: currentUser.location.latitude,
      longitude: currentUser.location.longitude,
      province: currentUser.address.province,
      provinceCode: currentUser.address.province,
      city: currentUser.address.city,
      postalCode: currentUser.address.postalCode,
      street: currentUser.address.street,
      unitNumber: currentUser.address.unitNumber,
      fullAddress: formatFullAddress(currentUser.address),
    };

    history.push({
      pathname: CREATE_APPOINTMENT_URL,
      state: { people: selectedMembers, address: preFilledAddress },
    });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Avvy - {t("selectMembers.title")}</title>
      </Helmet>
      <main style={{ minHeight: "90vh" }} className="d-flex flex-column appointment-page">
        <Container>
          <div className="d-flex flex-column">
            <div className="d-flex flex-column gap-16">
              <span onClick={() => props.history.goBack()} className="cursor-pointer">
                <Svg className="page-back-arrow" name="arrow-left" />
              </span>
              <h2 className="page-header">{t("selectMembers.title")}</h2>
            </div>

            <div
              className="gap-32"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
                gridTemplateRows: "minmax(1fr, auto)",
              }}
            >
              {members.length === 0 && !loading && !error && (
                <div className="avvy-card">
                  <span className="sub-text text-normal">{t("selectMembers.noMembers")}</span>
                </div>
              )}

              {members.map((member) => {
                return (
                  <MembersCard
                    key={member.id}
                    member={member}
                    onChecked={() => {
                      onMemberSelected(member.id);
                    }}
                  />
                );
              })}
            </div>

            {loading && <Loader title={t("common.loading")} />}
            {error && (
              <div
                className="error-alert mt-3 mx-auto"
                style={{ padding: "0.6rem", maxWidth: "100%" }}
              >
                {error}
              </div>
            )}

            {!error && !loading && nextPageToken && (
              <div className="d-flex items-center mx-auto mt-4">
                <Button
                  outline
                  disabled={loading}
                  color="primary"
                  onClick={() => {
                    getMembers(nextPageToken);
                  }}
                >
                  {t("memberSelect.loadMore")}
                </Button>
              </div>
            )}

            <hr style={{ margin: "32px 0" }} />

            <div
              className="d-flex items-center gap-32 mx-auto"
              style={{ width: "100%", maxWidth: "500px" }}
            >
              <Button
                className="px-5 m-0 mr-auto"
                outline
                disabled={loading}
                style={{ whiteSpace: "nowrap" }}
                color="primary"
                onClick={() => setShowMemberSidebar(true)}
              >
                {t("memberSelect.newButton")}
              </Button>

              <Button
                className="px-5 m-0 ml-auto"
                color="primary"
                disabled={!members.find((m) => m.checked === true) || loading}
                style={{ whiteSpace: "nowrap" }}
                onClick={() => goToAppointmentBooking()}
              >
                {t("memberSelect.addButton")}
              </Button>
            </div>
          </div>
        </Container>
      </main>

      <EditAddFamilyMemberView
        mode="new"
        sidebarGroup="book-add-member"
        onCreated={(person) => onMemberCreated(person)}
        toggle={() => setShowMemberSidebar(false)}
        isOpen={showMemberSidebar}
      />
    </React.Fragment>
  );
};

export default SelectMember;
