import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { parseError } from "../../helpers/common";
import CorporateProfileView from "./ProfileView";
import { Button } from "reactstrap";
import { Redirect } from "react-router-dom";
import { DASHBOARD_URL, AUTH_LOGIN_URL } from "../../urls";
import "./index.css";
import Loader from "../../components/Loader";
import authProvider from "../../helpers/authProvider";

const CorporateLinkView = (props) => {
  const { t } = useTranslation("common");
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [corporateCode, setCorporateCode] = useState(null);

  useEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });

    let searchParams = new URLSearchParams(props.location.search);
    let code = searchParams.get("account");
    setCorporateCode(code);
  }, [props.location]);

  useEffect(() => {
    fetchCorporateProfile(corporateCode);
  }, [corporateCode]);

  const fetchCorporateProfile = (code) => {
    setLoading(true);
    setError(null);
    setProfile(null);

    if (!code) {
      setLoading(false);
      return;
    }

    axios
      .get(`${process.env.REACT_APP_API_URL}/corporate/public-profile/${code}`)
      .then((res) => {
        setLoading(false);
        setProfile(res.data);
      })
      .catch((err) => {
        let error = parseError(err);
        setError(error);
        setLoading(false);
      });
  };

  if (authProvider.isAuthenticated()) {
    let searchParams = new URLSearchParams(props.location.search);
    let code = searchParams.get("account");
    sessionStorage.setItem("corporate-code-link", code);

    return <Redirect to={DASHBOARD_URL} />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Avvy - {t("corporate.pageTitle")}</title>
      </Helmet>

      <main
        style={{ minHeight: "90vh", paddingBottom: 0, paddingTop: 64 }}
        className="d-flex items-center justify-content-center"
      >
        <section className="corporate-link-section">
          <h3>Avvy {t("corporate.pageTitle")}</h3>

          {loading && <Loader className="mb-6 mt-4" title={t("common.loading")} />}
          {error && (
            <div className="error-alert mt-4 mb-6 mx-auto" style={{ maxWidth: "100%" }}>
              {error}
            </div>
          )}

          {profile !== null && (
            <React.Fragment>
              <CorporateProfileView profile={profile} />
              <div className="separator horizontal"></div>
              <p
                className="corporate-link-note"
                dangerouslySetInnerHTML={{
                  __html: t("corporate.linkNote").replaceAll("{0}", profile.name),
                }}
              ></p>

              {!profile.linkAllowed && (
                <div
                  className="warning-alert mb-4 mx-auto"
                  style={{ padding: "0.6rem", maxWidth: "100%" }}
                >
                  {t("corporate.linkNotAllowed").replaceAll("{0}", profile.name)}
                </div>
              )}

              <Button
                className="btn-accent"
                outline={!profile.linkAllowed}
                onClick={() => {
                  if (profile.linkAllowed) {
                    sessionStorage.setItem("corporate-code-link", corporateCode);
                  }
                  props.history.push(AUTH_LOGIN_URL);
                }}
              >
                {profile.linkAllowed ? t("corporate.signInBtnText") : t("dashboard.cancelBtn")}
              </Button>
            </React.Fragment>
          )}
        </section>
      </main>
    </React.Fragment>
  );
};
export default CorporateLinkView;
