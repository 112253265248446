import React from "react";

type AbortMapType = {
  [key: string]: AbortController;
};

export default function useAbortController(id: string = "default") {
  const abortControllerRef = React.useRef<AbortMapType>({});

  React.useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        Object.keys(abortControllerRef.current).forEach((key) => {
          if (abortControllerRef.current[key] !== undefined) {
            abortControllerRef.current[key].abort();
          }
        });
      }
    };
  }, []);

  const getSignal = React.useCallback(() => {
    if (!abortControllerRef.current || abortControllerRef.current[id] === undefined) {
      abortControllerRef.current[id] = new AbortController();
    } else {
      abortControllerRef.current[id].abort();
      abortControllerRef.current[id] = new AbortController();
    }
    return abortControllerRef.current[id].signal;
  }, []);

  return getSignal;
}
