import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
import axios from "axios";
import Checkbox from "../../../components/Checkbox";
import Loader from "../../../components/Loader";
import moment from "moment";
import "moment/locale/fr";
import ReactDatetime from "react-datetime";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import { Link } from "react-router-dom";
import { AUTH_LOGIN_URL, AUTH_SIGN_UP_PHONE_VERIFICATION } from "../../../urls";
import AddressInput from "../../../components/AddressInput";
import Svg from "../../../components/Svg";
import { useTranslation } from "react-i18next";
import { dateFormat, parseError } from "../../../helpers/common";

const SignUpPersonalInfo = (props) => {
  const { t, i18n } = useTranslation("common");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState(null);
  const [unitNumber, setUnitNumber] = useState("");
  const [dob, setDob] = useState();
  const [phone, setPhone] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [emailAvailabilityLoading, setEmailAvailabilityLoading] = useState(false);
  const [emailAvailabilityError, setEmailAvailabilityError] = useState(null);
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastNameError, setLastNameError] = useState(null);
  const [DOBError, setDOBError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [tosAgreed, setTosAgreed] = useState(false);

  useEffect(() => {
    window.scrollTo({
      behavior: "smooth",
      top: 0,
    });

    if (window.signupState) {
      setFirstName(window.signupState.firstName || "");
      setLastName(window.signupState.lastName || "");
      setEmail(window.signupState.email || "");
      setDob(window.signupState.dob || "");
      setAddress(window.signupState.address ? window.signupState.address : null);
      setUnitNumber(window.signupState.address ? window.signupState.address.unitNumber : "");
      setPhone(window.signupState.phone || "");
      setTosAgreed(window.signupState.tosAgreed || false);
    } else {
      setFirstName("");
      setLastName("");
      setEmail("");
      setDob(undefined);
      setAddress(null);
      setUnitNumber("");
      setPhone("");
      setEmailAvailabilityError(null);
      setEmailAvailabilityLoading(false);
      setFirstNameError(null);
      setLastNameError(null);
      setDOBError(null);
      setTosAgreed(false);
    }
  }, []);

  const handleNameValidation = (e, type) => {
    const value = e.target.value;
    if (type !== "email") {
      if (type === "firstName") {
        setFirstName(e.target.value);
        setFirstNameError(
          value ? null : <small className="text-danger">{t("personalInfo.firstNameError")}</small>
        );
      } else if (type === "lastName") {
        setLastName(e.target.value);
        setLastNameError(
          value ? null : <small className="text-danger">{t("personalInfo.lastNameError")}</small>
        );
      }
    } else {
      setEmail(e.target.value);
      if (e.target.value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) == null || e.target.value === "") {
        setEmailAvailabilityError(null);
        setEmailError(<small className="text-danger">{t("forgotPassword.error")}</small>);
      } else {
        setEmailAvailabilityError(null);
        setEmailError(null);
      }
    }
  };

  const checkEmailAvailability = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/account/email-availability?email=${encodeURIComponent(
          email
        )}`
      );

      if (res.data.available) {
        return { available: true, error: null };
      } else {
        return { available: false, error: t("personalInfo.emailTakenError") };
      }
    } catch (err) {
      let error = parseError(err);
      return { available: false, error: error };
    }
  };

  const disableFutureDt = (current) => {
    return current.isBefore(moment());
  };

  const onNextStep = async () => {
    setEmailAvailabilityLoading(true);
    setEmailAvailabilityError(null);

    const emailResult = await checkEmailAvailability();
    if (emailResult.available) {
      setEmailAvailabilityLoading(false);
      address.unitNumber = unitNumber;
      if (!window.signupState) {
        window.signupState = {};
      }
      window.signupState.firstName = firstName;
      window.signupState.lastName = lastName;
      window.signupState.email = email;
      window.signupState.phone = phone;
      window.signupState.dob = dob;
      window.signupState.address = address;
      window.signupState.tosAgreed = tosAgreed;
      props.history.push(AUTH_SIGN_UP_PHONE_VERIFICATION);
    } else {
      setEmailAvailabilityLoading(false);
      setEmailAvailabilityError(<small className="text-danger">{emailResult.error}</small>);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Avvy - {t("personalInfo.title")}</title>
      </Helmet>

      <div className="d-flex flex-column" style={{ width: "90%", maxWidth: "480px" }}>
        <Link to={AUTH_LOGIN_URL}>
          <Svg className="page-back-arrow" name="arrow-left" />
        </Link>

        <h2 className="mb-3">{t("welcome.createAccount")}</h2>

        <div className="w-full d-flex flex-column gap-16">
          <FormGroup>
            <Label className="theme-dark-text bold">{t("personalInfo.firstNameLabel")}*</Label>
            <InputGroup className="input-group-alternative">
              <Input
                required
                placeholder={t("personalInfo.firstNamePlaceholder")}
                type="text"
                value={firstName}
                maxLength={50}
                onChange={(e) => handleNameValidation(e, "firstName")}
              />
            </InputGroup>
            {firstNameError}
          </FormGroup>

          <FormGroup>
            <Label className="theme-dark-text bold">{t("personalInfo.lastNameLabel")}*</Label>
            <InputGroup className="input-group-alternative">
              <Input
                required
                placeholder={t("personalInfo.lastNamePlaceholder")}
                type="text"
                value={lastName}
                maxLength={50}
                onChange={(e) => handleNameValidation(e, "lastName")}
              />
            </InputGroup>
            {lastNameError}
          </FormGroup>

          <FormGroup>
            <Label className="theme-dark-text bold">{t("personalInfo.emailLabel")}*</Label>
            <InputGroup className="input-group-alternative">
              <Input
                onBlur={(event) => {
                  if (!event.target.value) {
                    setEmailError(
                      <small className="text-danger">{t("forgotPassword.error")}</small>
                    );
                  } else {
                    handleNameValidation(event, "email");
                  }
                }}
                required
                placeholder={t("personalInfo.emailPlaceholder")}
                type="email"
                value={email}
                onChange={(e) => handleNameValidation(e, "email")}
              />
            </InputGroup>
            {emailAvailabilityError || emailError}
          </FormGroup>

          <FormGroup style={{ position: "relative" }}>
            <Label className="theme-dark-text bold">{t("personalInfo.phoneLabel")}*</Label>
            <div className="form-group phone-input">
              <span
                style={{
                  position: "absolute",
                  padding: "0.5rem 0.6rem",
                  left: 0,
                  top: 30,
                  color: "var(--sub-text)",
                  fontSize: "0.875rem",
                }}
              >
                +1{" "}
              </span>
              <PhoneInput
                maxLength="16"
                defaultCountry="CA"
                extension="true"
                value={phone}
                onChange={(e) => {
                  let value = e;
                  setPhone(value);
                  if (value && !isPossiblePhoneNumber(value)) {
                    setPhoneError(
                      <small className="text-danger">{t("personalInfo.phoneError")}</small>
                    );
                  } else {
                    setPhoneError(null);
                  }
                }}
                onBlur={(event) => {
                  event.target.value === ""
                    ? setPhoneError(
                        <small className="text-danger">{t("personalInfo.phoneError")}</small>
                      )
                    : setPhoneError(null);
                }}
                required
              />
              {phoneError}
            </div>
          </FormGroup>

          <FormGroup>
            <Label className="theme-dark-text bold">{t("personalInfo.dateOfBirthLabel")}*</Label>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <ReactDatetime
                locale={i18n.language}
                dateFormat={dateFormat}
                onChange={(e) => {
                  if (e && typeof e.toDate === "function") {
                    setDob(e.toDate());
                    setDOBError(null);
                  } else {
                    setDOBError(
                      <small className="text-danger">{t("personalInfo.dateOfBirthError")}</small>
                    );
                    setDob(null);
                  }
                }}
                inputProps={{
                  className: "dob-readonly-input form-control",
                  placeholder: t("personalInfo.dateOfBirthLabel"),
                  readOnly: true,
                }}
                onBlur={(e) => {
                  if (!e) {
                    setDob(null);
                    setDOBError(
                      <small className="text-danger">{t("personalInfo.dateOfBirthError")}</small>
                    );
                  }
                }}
                timeFormat={false}
                isValidDate={disableFutureDt}
                closeOnSelect={true}
                value={dob}
              />
            </InputGroup>
            {DOBError}
          </FormGroup>
          <FormGroup>
            <Label className="theme-dark-text bold">{t("personalInfo.addressLabel")}*</Label>
            <AddressInput
              address={address}
              onUpdated={(addr) => {
                setAddress(addr);
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label className="theme-dark-text bold">
              {t("personalInfo.unitNumberPlaceholder")}
            </Label>
            <InputGroup className="input-group-alternative">
              <Input
                placeholder={t("personalInfo.unitNumberPlaceholder")}
                type="text"
                value={unitNumber}
                maxLength={50}
                onChange={(e) => setUnitNumber(e.target.value)}
              />
            </InputGroup>
          </FormGroup>

          <Checkbox
            className="theme-dark-text mt-2 ml-1 mr-1"
            key="tos-pp-agreed"
            checked={tosAgreed}
            labelHtml={t("personalInfo.agreeTosText")}
            id="tos-pp-agreed"
            handleChange={() => {
              setTosAgreed((prev) => !prev);
            }}
          />

          <Button
            className="w-full mt-4 mb-8 btn-accent"
            type="button"
            disabled={
              !firstName ||
              firstNameError != null ||
              !lastName ||
              lastNameError != null ||
              !email ||
              emailError != null ||
              !phone ||
              phoneError != null ||
              !dob ||
              DOBError != null ||
              !address ||
              emailAvailabilityLoading ||
              !tosAgreed
            }
            onClick={() => onNextStep()}
          >
            {t("personalInfo.buttonText")}
          </Button>

          {emailAvailabilityLoading && <Loader className="mt-3" title={t("common.loading")} />}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SignUpPersonalInfo;
