import Sarus from "@anephenix/sarus";
import * as chatTypes from "./chat-types";

export default class ChatClient extends EventTarget {
  readonly webSocketUrl: string;
  readonly accessTokenFactory: () => string | undefined;

  sequenceNumber: number = 0;
  socketInstance?: Sarus = undefined;

  constructor(endpointUrl: string, accessTokenFunc: () => string | undefined) {
    super();
    this.webSocketUrl = endpointUrl;
    this.accessTokenFactory = accessTokenFunc;
  }

  connect(): void {
    this.sequenceNumber = 0;

    const accessToken = this.accessTokenFactory();
    if (!accessToken) {
      console.error("User is not authorized. Chat connection cannot be established.");
      return;
    }

    this.socketInstance = new Sarus({
      url: `${this.webSocketUrl}?access_token=${accessToken}`,
      reconnectAutomatically: false,
      retryConnectionDelay: 10000,

      eventListeners: {
        open: [
          () => {
            this.handleOnConnected();
          },
        ],
        message: [
          (event: any) => {
            this.handleWebSocketMessage(event);
          },
        ],
        close: [
          () => {
            this.handleOnClose();
          },
        ],
        error: [
          (err: any) => {
            this.handleOnError(err);
          },
        ],
      },
    });
  }

  disconnect(): void {
    if (this.socketInstance) {
      this.socketInstance.disconnect(false);
    }
  }

  on(eventType: chatTypes.ChatEvents, handler: EventListenerOrEventListenerObject): void {
    this.addEventListener(eventType, handler);
  }

  off(eventType: chatTypes.ChatEvents, handler: EventListenerOrEventListenerObject): void {
    this.removeEventListener(eventType, handler);
  }

  /* Chat-specific calls */
  requestChatRoom(chatRoomId: string): void {
    const request: chatTypes.GetChatRoomRequest = {
      ChatRoomId: chatRoomId,
    };

    this.sendRequest(request, chatTypes.ChatFunctions.GetChatRoom);
  }

  subscribeToChatRoom(chatRoomId: string): void {
    const request: chatTypes.SubscribeToChatRoomRequest = {
      ChatRoomId: chatRoomId,
    };

    this.sendRequest(request, chatTypes.ChatFunctions.SubscribeToChatRoom);
  }

  getMessages(chatRoomId: string, nextPageToken?: string, limit: number = 20): void {
    const request: chatTypes.GetChatRoomMessagesListRequest = {
      ChatRoomId: chatRoomId,
      Limit: limit,
      NextToken: nextPageToken,
      SortDirection: 1,
    };

    this.sendRequest(request, chatTypes.ChatFunctions.GetChatRoomMessagesList);
  }

  sendMessage(chatRoomId: string, messageBody: string): void {
    const request: chatTypes.CreateChatRoomMessageRequest = {
      ChatRoomId: chatRoomId,
      MessageBody: messageBody,
    };

    this.sendRequest(request, chatTypes.ChatFunctions.CreateChatRoomMessage);
  }

  setTypingStatus(chatRoomId: string, isTyping: boolean): void {
    const request: chatTypes.SetUserTypingStatusRequest = {
      ChatRoomId: chatRoomId,
      IsTyping: isTyping,
    };

    this.sendRequest(request, chatTypes.ChatFunctions.SetUserTypingStatus);
  }

  markChatRoomAsRead(chatRoomId: string): void {
    const request: chatTypes.MarkChatAsReadRequest = {
      ChatRoomId: chatRoomId,
    };

    this.sendRequest(request, chatTypes.ChatFunctions.MarkChatAsRead);
  }

  /* Handle internal web socket events */
  private sendRequest(requestData: chatTypes.ChatRequest, functionName: string) {
    const wsRequest: chatTypes.ChatBaseMessage = {
      MessageType: 0,
      SequenceNumber: ++this.sequenceNumber,
      FunctionName: functionName,
      Payload: JSON.stringify(requestData),
    };

    this.socketInstance?.send(JSON.stringify(wsRequest, null, "  "));
  }

  private handleWebSocketMessage(event: any) {
    const messageData: chatTypes.ChatBaseMessage = JSON.parse(event.data);
    if (messageData) {
      this.sequenceNumber =
        this.sequenceNumber >= messageData.SequenceNumber
          ? this.sequenceNumber
          : messageData.SequenceNumber;

      switch (messageData.FunctionName) {
        case chatTypes.ChatFunctions.AuthenticationSuccess:
          this.dispatchEvent(
            new CustomEvent(chatTypes.ChatEvents.onConnected, {
              detail: JSON.parse(messageData.Payload),
            })
          );
          break;
        case chatTypes.ChatFunctions.GetChatRoom:
          this.dispatchEvent(
            new CustomEvent(chatTypes.ChatEvents.onChatRoomReceived, {
              detail: JSON.parse(messageData.Payload),
            })
          );
          break;
        case chatTypes.ChatFunctions.ChatRoomMessageReceived:
        case chatTypes.ChatFunctions.CreateChatRoomMessage:
          this.dispatchEvent(
            new CustomEvent(chatTypes.ChatEvents.onMessagesReceived, {
              detail: JSON.parse(messageData.Payload),
            })
          );
          break;
        case chatTypes.ChatFunctions.GetChatRoomMessagesList:
          this.dispatchEvent(
            new CustomEvent(chatTypes.ChatEvents.onMessagesHistoryReceived, {
              detail: JSON.parse(messageData.Payload),
            })
          );
          break;
        case chatTypes.ChatFunctions.UserTypingStatusChanged:
          this.dispatchEvent(
            new CustomEvent(chatTypes.ChatEvents.onTypingStatusChanged, {
              detail: JSON.parse(messageData.Payload),
            })
          );
          break;
        case chatTypes.ChatFunctions.MarkChatAsRead:
          this.dispatchEvent(
            new CustomEvent(chatTypes.ChatEvents.onMarkAsRead, {
              detail: JSON.parse(messageData.Payload),
            })
          );
          break;
      }
    }
  }

  private handleOnConnected() {}

  private handleOnClose() {
    this.sequenceNumber = 0;

    if (this.socketInstance) {
      const accessToken = this.accessTokenFactory();
      if (!accessToken) {
        this.socketInstance.reconnectAutomatically = false;
        return;
      } else {
        this.socketInstance.url = `${this.webSocketUrl}?access_token=${accessToken}`;
      }
    }

    this.dispatchEvent(
      new CustomEvent(chatTypes.ChatEvents.onDisconnected, {
        detail: null,
      })
    );
  }

  private handleOnError(error: any) {
    console.error(error);
  }
}
