export const HOME_URL = "/";
export const GET_STARTED = "/get-started";
export const LANGUAGE = "/language";

export const AUTH_WELCOME_URL = "/auth/welcome";
export const AUTH_LOGIN_URL = "/auth/login";
export const AUTH_FORGOT_PASSWORD_URL = "/auth/password-forgot";
export const AUTH_RESET_PASSWORD_URL = "/auth/password-reset";

export const AUTH_SIGN_UP_PERSONAL_INFO_URL = "/sign-up/profile";
export const AUTH_SIGN_UP_PASSWORD_URL = "/sign-up/password";
export const AUTH_SIGN_UP_PHONE_VERIFICATION = "/sign-up/phone-verification";
export const AUTH_SIGN_UP_EMAIL_VERIFICATION = "/sign-up/email-verification";

export const DASHBOARD_URL = "/dashboard";

export const CREATE_APPOINTMENT_URL = "/appointment/create";
export const CREATE_APPOINTMENT_MEMEBER_URL = "/appointment/create/family-members";
export const EDIT_APPOINTMENT_URL = "/appointment/:id/edit";
export const VIEW_APPOINTMENT_URL = "/appointment/:id";

export const AUTH_PRIVACY_POLICY_PAGE = "/privacy-policy";
export const AUTH_TERMS_OF_SERVICE_PAGE = "/terms-of-service";

export const CORPORATE_LINK_PAGE = "/corporate/join";
