import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import React, { useState, useRef, useCallback, useEffect } from "react";
import MapGL, { Marker } from "react-map-gl";
import Geocoder from "react-map-gl-geocoder";
import SideBar from ".";
import { Button, FormGroup, InputGroup, Input, Label } from "reactstrap";
import Svg from "../../components/Svg";
import { useTranslation } from "react-i18next";
import mapboxgl from "mapbox-gl/dist/mapbox-gl";
import MapboxLanguage from "@mapbox/mapbox-gl-language";
import { detectDefaultLanguage, formatFullAddress } from "../../helpers/common";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const EditAddressMap = (props) => {
  const { t } = useTranslation("common");
  const mapRef = useRef();
  const geocoderContainerRef = useRef();
  const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), []);
  const [viewport, setViewport] = useState({
    latitude: 45.552411,
    longitude: -73.688447,
    zoom: 11,
  });

  const [address, setAddress] = useState(props.address || null);
  const [unitNumber, setUnitNumber] = useState(props.address ? props.address.unitNumber || "" : "");
  const [marker, setMarker] = useState(props.address || null);
  const [addressInput, setAddressInput] = useState("");

  const handleGeocoderViewportChange = useCallback(
    (newViewport) => {
      const geocoderDefaultOverrides = { transitionDuration: 1000 };

      return handleViewportChange({
        ...newViewport,
        ...geocoderDefaultOverrides,
      });
    },
    [handleViewportChange]
  );

  useEffect(() => {
    setAddress(props.address || null);
    if (props.address && props.address.latitude && props.address.longitude) {
      setMarker(props.address || null);
    } else {
      setMarker(null);
    }
    setUnitNumber(props.address ? props.address.unitNumber || "" : "");
  }, [props.address]);

  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current.getMap();
      const language = new MapboxLanguage({
        defaultLanguage: detectDefaultLanguage().indexOf("fr") > -1 ? "fr" : "en",
      });
      map.addControl(language);
    }
  }, [mapRef]);

  const setMarkerLocation = (result) => {
    let province, provinceCode, postCode, city;

    if (result.context) {
      result.context.forEach((v, i) => {
        if (v.id.indexOf("postcode") >= 0) {
          postCode = v.text;
        }
        if (v.id.indexOf("region") >= 0) {
          province = v.text;
          provinceCode = v.short_code.replace("CA-", "");
        }
        if (v.id.indexOf("place") >= 0) {
          city = v.text;
        }
      });
    }

    let street = `${result.address || ""} ${result.text}`.trim();
    let data = {
      latitude: result.center[1],
      longitude: result.center[0],
      province: province,
      provinceCode: provinceCode,
      city: city,
      postalCode: postCode,
      street: street,
      unitNumber: unitNumber,
    };

    data.fullAddress = formatFullAddress(data);

    setMarker(data);
    setAddressInput("");
    setAddress(data);
  };

  const onAddressSave = () => {
    props.callBack(address);
    props.toggle();
  };

  const Footer = () => {
    return (
      <div className="d-flex flex-row">
        <Button
          disabled={!address}
          size="sm"
          className="px-5 py-2 ml-auto btn-accent"
          onClick={() => {
            onAddressSave();
          }}
        >
          {t("newBooking.btnSave")}
        </Button>
      </div>
    );
  };

  return (
    <SideBar {...props} footer={<Footer />}>
      <div className="d-flex flex-column gap-6">
        <div className="d-flex flex-column gap-6">
          <span className="semibold theme-dark-text">{props.title}</span>
          <div
            ref={geocoderContainerRef}
            className="theme-dark-text semibold d-flex flex-row"
            style={{
              width: "100%",
              alignItems: "center",
            }}
          ></div>
        </div>

        <Geocoder
          types="address"
          mapRef={mapRef}
          onViewportChange={handleGeocoderViewportChange}
          containerRef={geocoderContainerRef}
          mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
          position="top-left"
          placeholder={t("newBooking.addressPlaceholder")}
          inputValue={addressInput}
          marker={false}
          onResult={(result) => setMarkerLocation(result.result)}
          countries="ca"
          language={detectDefaultLanguage().indexOf("fr") > -1 ? "fr" : "en"}
        />

        <FormGroup style={{ marginTop: "6px" }}>
          <Label style={{ fontSize: "1rem", fontWeight: 500 }} className="theme-dark-text">
            {t("personalInfo.unitNumberPlaceholder")}
          </Label>
          <InputGroup className="input-group-alternative">
            <Input
              placeholder={t("personalInfo.unitNumberPlaceholder")}
              type="text"
              autoComplete="off"
              value={unitNumber}
              style={{ height: "auto" }}
              maxLength={50}
              onChange={(e) => {
                setUnitNumber(e.target.value);
                if (address) {
                  const addressCopy = Object.assign({}, address);
                  addressCopy.unitNumber = e.target.value || "";
                  addressCopy.fullAddress = formatFullAddress(addressCopy);
                  setAddress(addressCopy);
                }
              }}
            />
          </InputGroup>
        </FormGroup>

        <div
          style={{
            borderRadius: "6px",
            border: "1px solid var(--border-color)",
            overflow: "hidden",
          }}
        >
          <MapGL
            ref={mapRef}
            {...viewport}
            width="100%"
            height="calc(100vh - 510px)"
            mapStyle="mapbox://styles/mapbox/light-v10"
            onViewportChange={handleViewportChange}
            mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_KEY}
          >
            {marker && (
              <Marker
                latitude={marker.latitude}
                longitude={marker.longitude}
                offsetLeft={-20}
                offsetTop={-48}
              >
                <Svg name="icon_location" className="w-32 h-32 theme-text" />
              </Marker>
            )}
          </MapGL>
        </div>

        <div
          style={{ position: "inherit", margin: "6px 0" }}
          className="separator horizontal"
        ></div>
        {address && <span className="theme-dark-text semibold">{address.fullAddress}</span>}
      </div>
    </SideBar>
  );
};

export default EditAddressMap;
