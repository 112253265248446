import React from "react";
import MaskShapeImage from "../components/MaskShapeImage/index.js";
import Svg from "../components/Svg";

const IndexLayout = (props) => {
  return (
    <React.Fragment>
      <main className="landing-page">
        <section className="section-landing-cover my-0">
          <MaskShapeImage index={0} image={props.image} className="landing-page-mask" />
          <div className="d-flex px-6 flex-column items-center justify-content-center w-half z-2">
            <div className="flex-column d-flex items-center" style={{ maxWidth: "400px" }}>
              {props.children}
            </div>
          </div>
        </section>
      </main>
    </React.Fragment>
  );
};

export default IndexLayout;
