import React from "react";

const Svg = ({ name, folder = "svg", ...rest }) => {
  const ImportedIconRef = React.useRef(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    let isCancelled = false;
    setLoading(true);

    const importIcon = async () => {
      if (isCancelled) return;

      try {
        ImportedIconRef.current = (
          await import(
            `!!@svgr/webpack?-svgo,+titleProp,+ref!../../assets/img/${folder}/${name}.svg`
          )
        ).default;
      } catch (err) {
        console.warn(err);
      } finally {
        if (!isCancelled) {
          setLoading(false);
        }
      }
    };

    importIcon();

    return () => {
      isCancelled = true;
    };
  }, [name, folder]);

  if (!loading && ImportedIconRef.current) {
    const { current: ImportedIcon } = ImportedIconRef;
    return <ImportedIcon {...rest} />;
  }

  return null;
};

export default Svg;
