import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import Loader from "./components/Loader";
import AppRouter from "./components/Router";
import { ToastContainer } from "react-toastify";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18next from "i18next";
import common_fr from "./translations/fr/common.json";
import common_en from "./translations/en/common.json";
import { changeLanguage, detectDefaultLanguage } from "./helpers/common";
import authProvider from "./helpers/authProvider";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import "react-toastify/dist/ReactToastify.css";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/scss/argon-design-system-react.scss?v1.1.0";
import "./assets/css/layout.css";
import "./assets/css/avvy.css";
import "./assets/css/responsive.css";

if (process.env.REACT_APP_ENV !== "development") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.8,
    environment: process.env.REACT_APP_ENV,
  });
}

i18next.init({
  interpolation: { escapeValue: false },
  lng: detectDefaultLanguage(),
  resources: {
    en: {
      common: common_en,
    },
    fr: {
      common: common_fr,
    },
  },
});

const App = () => {
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation("common");

  useEffect(() => {
    window.translations = t;
    axios.interceptors.request.use(
      (config) => {
        if (authProvider.isAuthenticated()) {
          const session = authProvider.getSession();
          config.headers.Authorization = `Bearer ${session.accessToken}`;
        }

        config.headers["Accept-Language"] = detectDefaultLanguage();
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    const createAxiosResponseInterceptor = () => {
      const interceptor = axios.interceptors.response.use(
        (response) => response,
        (error) => {
          const isAuthenticated = authProvider.isAuthenticated();
          const session = authProvider.getSession();

          if (!error.request || error.request.status !== 401 || !isAuthenticated) {
            return Promise.reject(error);
          }

          axios.interceptors.response.eject(interceptor);

          return authProvider
            .refreshToken(session.refreshToken)
            .then((res) => {
              authProvider.setSession(res.data);
              return axios(error.response.config);
            })
            .catch((err) => {
              console.error(err);
              authProvider.logout();
              window.location.href = "/auth/login";
            })
            .finally(() => {
              createAxiosResponseInterceptor();
            });
        }
      );
    };

    createAxiosResponseInterceptor();

    if (authProvider.isAuthenticated()) {
      authProvider
        .getUserProfile()
        .then((res) => {
          if (res.data.language !== detectDefaultLanguage()) {
            changeLanguage(i18n, t, res.data.language);
          }
          authProvider.setUser(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          authProvider.logout();
          window.location.href = "/auth/login";
        });
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, []);

  let view;
  if (loading) {
    view = (
      <div className="splash-screen">
        <Loader title={t("common.loading")} />
      </div>
    );
  } else {
    view = (
      <React.Fragment>
        <AppRouter />
        <ToastContainer
          position="top-right"
          autoClose={6000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </React.Fragment>
    );
  }

  return <React.Fragment>{view}</React.Fragment>;
};

ReactDOM.render(
  <React.Fragment>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.Fragment>,
  document.getElementById("root")
);
